import React from "react";
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import { CASE_STUDIES } from "../../constants/case-study";
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { useParams } from "react-router-dom";

function getCaseStudyById(caseStudies, id) {
    return caseStudies.find(caseStudy => caseStudy._id === id);
}

const ClientsContentPage = () => {
    const { clientId } = useParams();

    const caseStudy = getCaseStudyById(CASE_STUDIES, clientId);

    const title = "Case Study & Partners"
    return (
        <div>
            <NavbarPages />
            <HeaderPages title={title} />
            <section id="products-and-services" className="bg-light wm-bg-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-left wow ">
                            <h3 className="mb-3">{caseStudy?.title}</h3>
                            <div className="d-flex flex-row gap-3">
                                <img src={`/images/site/customer/${caseStudy?.case_study_picture_name}`} style={{ "objectFit": "contain" }} height={300} width={300} alt={caseStudy?.title} />
                                <div className="d-flex flex-column gap-3 justify-content-start py-4">
                                    {caseStudy?.contract && <div > <strong className="gradient-text1 h4 mb-1">Contract</strong> <p>{caseStudy?.contract}</p> </div>}
                                    <div> <strong className="gradient-text1 h4 mb-1">The Challenge </strong> <p>{caseStudy?.challenges}</p> </div>

                                </div>
                            </div>
                            <div className="d-flex flex-column gap-3 mb-4">
                                <div>
                                    <div className="mt-4"> <strong className="gradient-text1 h4 mb-1">Services</strong><br />
                                        <Stack direction="horizontal" className="d-flex gap-2 py-2">
                                            {caseStudy?.services.map((service) => {
                                                return <Badge className="custom-badge-bg" pill>{service}</Badge>;
                                            })}
                                        </Stack>
                                    </div>
                                    <div className="mt-4 mb-2"><strong className="gradient-text1 h4">Our Work</strong></div>
                                    {caseStudy?.work.map((work) => {
                                        switch (work.type) {
                                            case "textarea":
                                                return <p>{work.value}</p>;
                                            case "list":
                                                return <div className="py-2">
                                                    <p>{work.title}</p>
                                                    <ul className="packages">
                                                        {work.value.map((value) => {
                                                            return <li><i className="fa fa-circle-o" aria-hidden="true"></i>{value} </li>
                                                        })}
                                                    </ul>
                                                </div>
                                                    ;
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <ContactInfo />
            <FooterMain />
        </div >
    );
};
export default ClientsContentPage
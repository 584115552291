import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';

const DataManagementPage = () => {
    const title = "Data Management";
    const subTitle = "Unlock Data Potential";
    return (
        <div>
            <NavbarPages />
            <HeaderPages title={title} subTitle={subTitle} />
            <section id="dev-sec-ops" className="bg-light wm-bg-section">
                <div className="container">
                    {/* Heading */}
                    <div className="row">
                        <div className="col-md-12 text-center wow ">
                            <div className="title d-inline-block">
                                <h6 className="mb-3">Transform Data into Insights</h6>
                                <h2 className="gradient-text1">Advanced Data Science Solutions</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-left wow ">
                            <p>Most enterprises can’t effectively manage their own data, but having reliable, quality data becomes more important every day. By partnering Optimoz, your enterprise can develop a cohesive data management strategy, platform, and set of processes that delivers accurate answers and insights drawn straight from your own data.</p>
                            <p className="my-2"> We employ leading experts in every data management discipline: </p>
                            <ul className="packages">
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Data strategy and architecture  </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Database management </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Data ingestion, transformation, and quality </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Data warehousing </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Data governance & compliance </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Data science and analytics </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Artificial intelligence & machine learning</li>
                            </ul>

                            <p className="mb-3"><strong className="gradient-text1">Data Management Process </strong> <p>Our four-step approach to data management enables enterprises to put data management technologies and processes in place quickly and rapidly build expertise: </p></p>

                            <div className='d-flex p-5 justify-content-center'>
                                <img src="/images/optimoz/data-management.gif" alt="Data Management" />
                            </div>

                            <ul className="packages">
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Plan </strong> – Perform research on the state of data in your enterprise to create a bespoke data management strategy that matches your business needs.  </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Products  & Technologies </strong> – Research and select the appropriate products and technologies for your data management platform and build an implementation team made up of Optimoz experts and your employees. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Deliver </strong> – Implement data management technologies, ingest data, and define and execute data management and governance processes. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Optimize </strong> – Analyze and continue to fine-tune performance to deliver more accurate results. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Train </strong> – Transfer knowledge to your employees, so they can manage and derive continuous value from your data management platform. </li>
                            </ul>

                            <p>For our customers, the benefits from best-in-class data management technologies and processes are significant: </p>


                            <ul className="packages">
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Improved access to data and reporting to support decision-making </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Improved process execution across the enterprise </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Increased transparency in operations </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Enhanced customer service </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Improved governance and regulatory compliance </li>
                            </ul>

                        </div>
                    </div>

                </div>
            </section>
            <FooterMain />
        </div>
    );
};

export default DataManagementPage;
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import BlogContent from "../../components/AppComponents/BlogContent";
import { OPTIMOZ_LOGO_BASE64, BLOG_DEFAULT_AUTHOR_NAME } from "../../constants/configuration";

// API 
import getEntityData from "../../apis/Entity/getEntity";
import getEntityDataList from "../../apis/Entity/getEntityList";

const BlogContentPage = () => {

    // Get the blog ID from the URL parameters
    const { blogId } = useParams();
    // State to store the fetched data
    const [blog, setBlog] = useState(null);
    const [users, setUsers] = useState([]);

    // useEffect(() => {
    //     const fetchUsers = async () => {
    //         try {
    //             const response = await getEntityDataList({
    //                 pageNumber: 1,
    //                 pageSize: 10,
    //                 apiUrl: "users",
    //             });
    //             setUsers(response.data);
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };
    //     fetchUsers();
    // }, []);

    const userInformation = useCallback((userId, userInfo) => {
        const user = userInfo?.find((user) => user._id === userId);
        if (user) {
            return user;
        }
        return BLOG_DEFAULT_AUTHOR_NAME;
    }, [])

    // useEffect to fetch data on component mount
    useEffect(() => {
        // Define an async function to fetch the data
        const fetchData = async () => {
            try {
                // Fetch data from the API
                const userResponse = await getEntityDataList({
                    pageNumber: 1,
                    pageSize: 10,
                    apiUrl: "users",
                });
                const response = await getEntityData(`blogs/${blogId}`);
                // Update the state with the fetched data
                response.data.author = userInformation(response.data.author, userResponse.data);
                setBlog(response.data);
            } catch (error) {
                // Handle any errors that occurred during the fetch
                console.error(error)
            }
        };
        // Call the fetchData function
        fetchData();
    }, []); // Empty dependency array ensures this runs only once on mount



    const userName = useCallback((user) => {
        if (user?.firstName) {
            return user.firstName + " " + (user?.lastName || "");
        }
    }, []);


    return (
        <div>
            <NavbarPages />
            <HeaderPages />
            <BlogContent
                blogTitle={blog?.blogTitle || "Blog Title"}
                blogExcerpt={blog?.excerpt || "Blog Excerpt"}
                blogPicture={blog?.blogPicture}
                blogContent={blog?.blog || "Blog Content"}
                author={blog?.author ? userName(blog.author) : BLOG_DEFAULT_AUTHOR_NAME}
                designation={blog?.author?.designation}
                avatar={blog?.author?.avatar || OPTIMOZ_LOGO_BASE64}
                publishedOn={blog?.datePosted || ""}
            />
            <ContactInfo />
            <FooterMain />
        </div>
    );
};
export default BlogContentPage
import React from "react";
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import { Vehicles } from "../../components/AppComponents/Vehicles";

const VehiclesPage = () => {
    const title = "Clients & Partners";
    const subTitle = "Optimoz Case Studies";
    return (
        <div>
            <NavbarPages />
            <HeaderPages title={title} subTitle={subTitle} />
            <Vehicles />
            <ContactInfo />
            <FooterMain />
        </div >
    );
};
export default VehiclesPage
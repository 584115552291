import React, { useState, useCallback, useEffect } from 'react';

import { JsonForms } from '@jsonforms/react';

import {
    materialRenderers,
    materialCells

} from '@jsonforms/material-renderers';

import { schema, uischema } from './schemas';

// Constant
import { DEFAULT_FORM_VALIDATION_MODE, FORM_VALIDATE_MODE } from '../../../constants/configuration';
import saveEntityData from '../../../apis/Entity/saveEntity';



export function Contact() {

    const [data, setData] = useState({});
    const [errors, setErrors] = useState([]);
    const [validationMode, setValidationMode] = useState(DEFAULT_FORM_VALIDATION_MODE);

    const [toast, setToast] = useState(false);

    // Define an async function to submit the data
    const submitData = useCallback(async (event, payload) => {

        event.preventDefault();

        // set validation mode to validate on save
        setValidationMode(FORM_VALIDATE_MODE);

        // check if there are any errors
        if (errors && errors.length > 0) {
            return;
        }

        try {
            await saveEntityData({ entityDataUrl: 'enquiries', data: payload });
            setToast(true);
            setData({});
            setErrors([]);
            setValidationMode(DEFAULT_FORM_VALIDATION_MODE);
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error(error)
        }
    }, [setToast, errors]);

    return (
        <section className="contact-section wm-bg-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="contact-title">
                            <h2 className="gradient-text1">Get in Touch</h2>
                            <br />   <br />
                            <p>We are easy to reach and happy to help. We are located within Washington, DC metro area in Rockville, MD, close to the I-270, I-495 and Reagan National, Dulles, and Baltimore-Washington International Airports.</p>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <br /><br />
                                Optimoz, Inc.<br />
                                2600 Tower Oaks Blvd Suite 610<br />
                                Rockville, MD 20852<br />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="contact-form">
                            <form onSubmit={(event) => submitData(event, data)}>
                                <JsonForms
                                    schema={schema}
                                    uischema={uischema}
                                    renderers={materialRenderers}
                                    cells={materialCells}
                                    validationMode={validationMode}
                                    data={data}
                                    onChange={({ errors, data }) => { setData(data); setErrors(errors); }}
                                />
                                <button type="submit"
                                    className="btn btn-large btn-gradient btn-rounded h-100 w-100">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: 1200 }}>
                        <div id="liveToast" className={toast ? 'toast show' : 'toast'} role="alert" aria-live="assertive" aria-atomic="true">
                            <div class="toast-header">
                                <strong class="me-auto">Enquiry submitted</strong>
                                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setToast(false)}></button>
                            </div>
                            <div class="toast-body">
                                Enquiry saved successfully!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
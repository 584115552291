import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';

const OptalkPage = () => {
    const title = "optalk";
    return (
        <div>
            <NavbarPages />
            <HeaderPages title={title} />
            <section id="products-and-services" className="bg-light wm-bg-section">
                <div className="container">
                    {/* Heading */}
                    <div className="row">
                        <div className="col-md-12 text-center wow ">
                            <div className="title d-inline-block">
                                <h6 className="mb-3">Unleash your enterprise with optalk.ai. </h6>
                                <h2 className="gradient-text1">Unleash your enterprise with optalk.ai.</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-left wow ">
                            <p>optalk.ai is Optimoz’s framework for maximizing the impact of AI across your enterprise. optalk.ai combines the best of commercial AI tools, purpose-built proprietary components, and solution development services to deliver high-performance AI applications.</p>
                            <p className="mb-3">Our optalk.ai framework is unique in several important ways: </p>

                            {/* <img src="/images/optimoz/healthit-solutions.gif" alt="Health IT Solutions" /> */}

                            <p className="mb-3"><strong className="gradient-text1">LLM agnostic</strong> - Use the LLM that is right for your AI applications. </p>
                            <p className="mb-3"><strong className="gradient-text1">Purpose-built components</strong> - Maximize usability, performance, and data integration and management. </p>
                            <p className="mb-3"><strong className="gradient-text1">Private, not public</strong> - Work securely with your data inside your enterprise – not on the public internet. </p>
                            <p className="mb-3"><strong className="gradient-text1">All data sources</strong> - Access and analyze any type of data from any drive, application, or database.
                                Flexible, powerful UI/UX – Interact productively with your AI applications.  </p>
                            <p className="mb-3"><strong className="gradient-text1">Deploy anywhere</strong> - Run AI applications on any of the leading cloud services or on premises.   </p>
                            <p className="mb-3"><strong className="gradient-text1">AI development expertise </strong> - Leverage our elite team to design, build, and operate your AI applications. </p>


                            <p><strong className="gradient-text1">Our Approach </strong></p>
                            <p className="mb-3">Optimoz works with your team through the AI application development lifecycle to deliver game-changing results using AI. Our four-phased approach is highly collaborative and focused on realizing business value.   </p>


                            <img src="/images/optimoz/optimoz-approach.png" alt="Health IT Solutions" />

                            <p><strong className="gradient-text1">Prepare </strong></p>
                            <ul className="packages">
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Analyze Systems & Data – Analyze systems of record, data quality, data management, practices, and infrastructure. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Prioritize Needs – Work with your team to identify the use cases where AI can have a positive, immediate impact. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Choose Technologies – Determine which AI technologies are appropriate for identified use cases. </li>
                            </ul>

                            <p><strong className="gradient-text1">Plan  </strong></p>
                            <ul className="packages">
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Design Applications – Create a technical design for your AI applications. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Create Product Plan – Develop product strategy and requirements. </li>
                            </ul>

                            <p><strong className="gradient-text1">Build  </strong></p>
                            <ul className="packages">
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Develop Applications – Build & test applications. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Train AI Model – Build integrations, ingest data, and begin training of AI model </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Implement DevSecOps Process – Ensure high performance of applications in production. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Acceptance Testing – Test applications with end-users and solicit feedback.  </li>
                            </ul>

                            <p><strong className="gradient-text1">Deploy, Optimize, & Operate </strong></p>
                            <ul className="packages">
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Deploy applications – Put applications into production for end-users. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Fine-Tune Application and LLM Model – Maximize performance in production. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Operate & Maintain – Monitor, maintain, and upgrade production application and LLM model. </li>
                            </ul>

                            <p><strong className="gradient-text1">What We Deliver </strong></p>
                            <p className="mb-3">Optimoz is built to serve the entire AI application development lifecycle.  Our team has the experience and expertise you need to deliver your AI applications successfully:</p>

                            <ul className="packages">
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>CTOs </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Product managers </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Business analysts </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Solution architects </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>AI technology experts </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Data scientists and engineers </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>AI-fluent software engineers </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>DevSecOps experts  </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Cloud engineers  </li>
                            </ul>

                            <p className="mb-3">We provide services targeted at making your AI applications successful in operation and impactful for your enterprise:  </p>


                            <p className="mb-3"><strong className="gradient-text1">AI business case development</strong> - to ensure that your enterprise sets the right priorities for your AI efforts. </p>

                            <p className="mb-3"><strong className="gradient-text1">AI platform evaluation and selection</strong> - to enable you to select the right technologies to support your AI plans. </p>
                            <p className="mb-3"><strong className="gradient-text1">Product management</strong> - to make sure that you deliver the right AI products for your enterprise on time and on budget. </p>
                            <p className="mb-3"><strong className="gradient-text1">AI application development</strong> - to build the best possible Ai solutions for your stakeholders. </p>
                            <p className="mb-3"><strong className="gradient-text1">AI Operations, including LLM Ops and ML Ops</strong> - to ensure that your applications are high performance in operation. </p>
                            <p className="mb-3"><strong className="gradient-text1">Data science & data management</strong> - to effectively manage the data used by your AI applications and maximize the business insights your enterprise receives. </p>
                            <p className="mb-3"><strong className="gradient-text1">Reporting and visualization</strong> - to present insights to support effective decision-making. </p>


                        </div>
                    </div>

                </div>
            </section>
            <FooterMain />
        </div>
    );
};

export default OptalkPage;
import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';

const CloudEngineering = () => {
    const title = "Cloud Engineering";
    const subTitle = "Streamline Infrastructure";
    return (
        <div>
            <NavbarPages />
            <HeaderPages title={title} subTitle={subTitle} />
            <section id="dev-sec-ops" className="bg-light wm-bg-section">
                <div className="container">
                    {/* Heading */}
                    <div className="row">
                        <div className="col-md-12 text-center wow ">
                            <div className="title d-inline-block">
                                <h6 className="mb-3">Boost Cloud Performance</h6>
                                <h2 className="gradient-text1">Optimize Networks & Cloud Performance</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-left wow ">
                            <p>Your enterprise wants to move key workloads and data to the Cloud. Operating effectively in the cloud presents some major challenges.  Cloud Engineering is the function that manages the cloud-based infrastructure, networks, and applications used by the enterprise.  An effective cloud engineering function is essential to operating an enterprise in the cloud, because it touches every key component of your technology ecosystem.  </p>
                            <div className='d-flex p-5 justify-content-center'>
                                <img src="/images/optimoz/cloud-engineering.png" alt="Cloud Engineering" />
                            </div>
                            <p className="my-2"> Optimoz offers various Cloud Engineering services:  </p>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <ul className="packages">
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>Cloud Providers </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>Programming </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>Containerization </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>Networking </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>DevSecOps </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>Database Management </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>APIs & Web Services </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>LLM Operations </li>
                                    </ul>

                                </div>
                                <div className='col-md-6'>
                                    <ul className="packages">
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>Virtualization   </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>OSes  </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>Cybersecurity  </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>Infrastructure as Code  </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>Storage Management  </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>Monitoring & Alerting  </li>
                                        <li><i className="fa fa-circle-o" aria-hidden="true"></i>Data Queuing and Streaming </li>
                                    </ul>

                                </div>
                            </div>

                            <ul className="packages">
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Cloud Strategy  </strong> – where we work with your enterprise to create a comprehensive approach for determining whether, when, and how to move and operate your workloads to the Cloud. Key elements include: </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Application Audit </strong> – to identify the applications that are close to Cloud ready versus needing the most work and, therefore, shouldn’t be moved. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Cloud Transformation Plan  </strong> – to provide a roadmap for moving your core applications to the Cloud. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Financial Analysis  </strong> – of the costs and benefits of moving your enterprise’s IT infrastructure and applications to the Cloud. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Human Capital Analysis  </strong> – to identify skills gaps and needs to support a Cloud transformation. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Refactor v. Rebuild Analysis  </strong> – to determine which applications should be refactored for the Cloud versus being either rebuilt from scratch or replaced with applicable COTS solutions. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Application and Technology Strategy  </strong> – examine all the factors involved in a cloud development or migration & modernization effort: customer needs, technical challenges, make versus buy decisions, talent needs, level of effort, and costs involved in bringing your application to the Cloud.  </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Technology Evaluation  </strong> – to select of key technologies your enterprise needs based on the desired business outcome, cost of delivery, skills of the current and desired team, and stakeholder and end-user expectations. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Cloud Development & Operations  </strong> – where we build new and refactor existing workloads for the cloud and then manage them in operation.  Our service offerings include: </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Rapid Assessment  </strong> – including intensive analysis of your applications and technology platforms to identify the roadblocks to migrating to and modernizing in and Cloud. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Technical Leadership  </strong> – for your technology development and IT operations teams. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Product Management  </strong> – to define and deliver the right feature set for users. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Solution Architecture  </strong> – to ensure that cloud applications will perform well in operation. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Software Engineering  </strong> – using next-generation capabilities, such as Rest APIs and microservices to speed development, increase maintainability, and support continuous refactoring as the needs of the enterprise change. </li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>DevSecOps & CI/CD  </strong> – to enable smooth deployment and operations and maximum security  </li>
                            </ul>

                        </div>
                    </div>

                </div>
            </section>
            <FooterMain />
        </div>
    );
};
export default CloudEngineering;
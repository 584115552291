import getAxiosInstance from '../getAxiosInstance';

const getEntityDataList = ({ pageNumber = 1, pageSize = 10, sortKey = null, sortOrder = -1, apiUrl }) => {
    const instance = getAxiosInstance();

    if (!apiUrl) {
        return Promise.reject({
            error: true,
            message: 'Api url should not be null or undefined',
        });
    }

    const params = {
        page_number: pageNumber,
        page_size: pageSize,
        sort_key: sortKey,
        sort_order: sortOrder
    };

    return instance.get(
        `/entitydata/${apiUrl}`,
        { params }
    );
};

export default getEntityDataList;
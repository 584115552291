import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import { Services } from '../../components/AppComponents/Services';

const ServicesPage = () => {
  const title = "Services";
  const subTitle = "Comprehensive Solutions Tailored for You.";
  return (
    <div>
        <NavbarPages/>
        <HeaderPages title={title} subTitle={subTitle}/>
        <Services/>
        <ContactInfo/>
        <FooterMain/>
    </div>
  );
};

export default ServicesPage;
export default function Aixmoz() {
    return (
        <section id="aixmoz">
            <div className="container">
                {/* Heading */}
                <div className="row">
                    <div className="col-md-12 text-center wow ">
                        <div className="title d-inline-block">
                            <h6 className="mb-3">Most reliable AI Platform: aixmoz</h6>
                            <h2 className="gradient-text1 mb-3">aixmoz / optalk</h2>
                            <p>Our optalk.ai framework combines the best of commercial AI tools, purpose-built proprietary components, and solution development services to deliver high-performance AI applications.  </p>
                        </div>
                    </div>
                </div>

                {/* App deatil */}
                <div className="row align-items-center text-center">

                    <div className="col-md-4 wow ">
                        {/* App deatil item */}
                        <div className="app-feature">
                            <i className="fa fa-lock gradient-text1" aria-hidden="true"></i>
                            <h4 className="mb-3">Private & Secure</h4>
                            <p>Built for security and privace-conscious enterprises</p>
                        </div>
                        {/* App deatil item */}
                        <div className="app-feature">
                            <i className="fa fa-edit gradient-text1" aria-hidden="true"></i>
                            <h4 className="mb-3">Purpose-built components</h4>
                            <p>Maximize usability, performance, and data integration and management</p>
                        </div>
                    </div>

                    {/* app slider */}
                    <div className="col-md-4 wow ">
                        {/* app Image */}
                        <div className="app-image">
                            <img src="images/iphone-img.png" alt="image" />
                            <div id="app-slider" className="owl-carousel owl-theme">
                                <div className="item">
                                    <img src="images/optimoz/cloud-435x780.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="images/optimoz/optalk-app-login-435x780.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="images/optimoz/optalk-435x780.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 wow ">
                        {/* App deatil item */}
                        <div className="app-feature">
                            <i className="fa fa-code gradient-text1" aria-hidden="true"></i>
                            <h4 className="mb-3">Powerful API</h4>
                            <p>Provides powerful API and API oriented architecture to connect any application.</p>
                        </div>
                        {/* App deatil item */}
                        <div className="app-feature">
                            <i className="fa fa-folder-open-o gradient-text1" aria-hidden="true"></i>
                            <h4 className="mb-3">All data sources </h4>
                            <p>Access and analyze any type of data from any drive, application, or database</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Authenticator from '../../helpers/Authenticator';

const LoginPage = () => {
  const {keycloak, initialized} = useKeycloak();

  useEffect(() => {
    if (!initialized) {
      return;
    }
    if (keycloak.authenticated) {
      Authenticator.setAuthenticated(keycloak);
    } else {
      Authenticator.login(keycloak);
    }
  }, [initialized, keycloak]);


  return (
    <div>
        Loading Login Page...
    </div>
  );
};

export default LoginPage;
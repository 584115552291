export const CASE_STUDIES = [
    {
        id: 1,
        _id: 'cdc',
        case_study_picture_name: "cdc-logo-300x300.png",
        title: "Center for Disease Control & Prevention – Department of Health & Human Services",
        contract: "Immunization Information System",
        challenges: "By law, every US state and territory is required to maintain an immunization registry and submit immunization data to the CDC. Currently, states submit data via a CDC-managed application called the Immunization Gateway. CDC developed and released the Immunization Gateway almost 30 years ago. The application is increasingly unreliable and hard to maintain. CDC needed a new version of the Immunization Gateway – built using modern web and cloud technologies – to more easily ingest and use immunization data.",
        services: [
            "Software Engineering",
            "DevSecOps",
            "Cloud Engineering",
            "Healthcare IT"
        ],
        work: [
            {
                type: "textarea",
                value: "CDC contracted with Optimoz to design, build, and operate the Immunization Gateway of the future. The Optimoz-built Immunization Gateway is now in production. It operates as a web service – enabling providers operating in a state to report data to a state registry. The state then reports that data to the CDC."
            },
            {
                type: "list",
                title: "For the CDC, the key benefits of the new Immunization Gateway are:",
                value: [
                    "A vastly improved registry, which enables the collection of a lot more data in industry-standard data formats.",
                    "Faster, more reliable data exchanges because the new gateway leverages industry-standard messaging and communications protocols.",
                    "Much better performance and reliability due to the use of modern web and cloud technologies."
                ]
            },
            {
                type: "textarea",
                value: "The CDC believes that it can eventually use the Optimoz- built gateway technology to create additional registries to capture data on multiple medical, vision, and hearing- related conditions."
            },
            {
                type: "list",
                title: "Optimoz used a variety of technologies to build the Immunization Gateway, including: ",
                value: [
                    "AWS cloud services ",
                    "AWS code commit & pipeline",
                    "Docker containers that support a microservices architecture",
                    "RedHat Linux",
                    "The JAVA programming language",
                    "Oracle database – used to carry over functions from the old system",
                    "The HL-7 health data standard for inter-operability"
                ]
            },
            {
                type: "textarea",
                value: "The Immigration Gateway is live for the US Virgin Islands and will be widely deployed over the next 6-12 months. "
            }

        ]
    },
    {
        id: 2,
        _id: 'md-judiciary',
        case_study_picture_name: "maryland-court.png",
        title: "Maryland Judiciary, Administrative Office of the Courts (AOC)",
        contract: "Turnkey Automated Document Creation System ",
        challenges: "The Maryland Judiciary’s approach to managing tenant evictions was predominantly paper-based and highly inefficient – creating issues for landlords and employees of the court. The Maryland Judiciary needed an automated solution that streamlined the process of managing the document flow for tenant evictions.",
        services: [
            "Software Engineering",
            "DevSecOps",
        ],
        work: [
            {
                type: "textarea",
                value: "Optimoz developed a custom application called the Turnkey Automated Document Creation System for managing evictions statewide for the state court system. The application enables landlords and officers of the court to create electronic documents related to evictions, assemble the eviction document packages in PDF format, and enable bulk printing of documents that are used in court. The application is used in all 28 districts of the Maryland court system."
            },
            {
                type: "list",
                title: "Optimoz used a variety of open-source technologies to build the Turnkey Document Creation System, including:",
                value: [
                    "Linux operating system",
                    "The JAVA Programming Language",
                    "Spring",
                    "Spring Boot",
                    "The MySQL database",
                    "The AngularJS framework",
                ]
            }
        ]
    },
    {
        id: 3,
        _id: 'inxeption',
        case_study_picture_name: "inxeption-300x300.png",
        title: "Inxeption – a logistics management company based in Silicon Valley",
        challenges: "Inxeption builds and operates a blockchain-driven technology platform for digitizing the global Industrial Commerce value chain. The Company had grown rapidly, and technical operations were becoming very complex and hard to manage. Inxeption needed to put in place world-class DevSecOps processes and a more scalable approach to managing its infrastructure to keep up with demand.  ",
        services: [
            "DevSecOps",
            "Cloud Engineering",
            "Data Management",
        ],
        work: [
            {
                type: "list",
                title: "Optimoz turned DevSecOps and infrastructure management into a competitive advantage for Inxeption.  We took a 4-part approach:",
                value: [
                    "We consolidated dozens of Kubernetes clusters – each supporting 8-10 customers – into a single Kubernetes cluster running on AWS Elastic Kubernetes Service Cluster (EKS). This approach enabled Inxeption to scale the cluster automatically as it added new customers.",
                    "We built and deployed an Ethereum based blockchain cluster into EKS – allowed Inxeption to write and maintain contracts so that they cannot be changed.",
                    "We consolidated hundreds of database instances into a single database residing in the AWS Relational Database Service (RDS) with the data segmented by customer to maximize security.",
                    "We implemented modern DevSecOps processes that enabled Inxeption to develop, deploy, and operate its platform more efficiently and effectively.",
                ]
            },
            {
                type: "list",
                title: "Optimoz used a variety of technologies to improve the performance of the Inxeption platform, including:",
                value: [
                    "AWS Elastic Kubernetes Service Cluster (EKS)",
                    "Etherium blockchain technologies",
                    "AWS Relational Database Service (RDS) ",
                    "Best-in-class tools for building and managing DevSecOps processes ",
                ]
            }
        ]
    },
    {
        id: 4,
        _id: 'dhs-uscis-aes',
        case_study_picture_name: "uscis-tics-600x600.png",
        title: "US Citizenship and Immigration Services (USCIS) – Department of Homeland Security",
        contract: "Architecture and Engineering Services ",
        challenges: "The primary application USCIS used to perform its core mission is the Electronic Immigration System (ELIS). ELIS is hosted on AWS and includes collection of APIs that are managed by Google’s Apigee API management system. USCIS needed to improve its ability to manage its collection of APIs, so that it could increase the reliability and performance of the ELIS application.",
        services: [
            "DevSecOps",
            "Cloud Engineering",
            "Software Engineering",
        ],
        work: [
            {
                type: "textarea",
                value: "Optimoz built systems and DevSecOps processes to manage, operate, and maintain the APIs used by the ELIS application inside of Apigee. As part of the solution, we manage multiple AWS clusters that contain the Apigee application. We use Red Hat’s Ansible automation solution to automate the management of Apigee clusters.  Optimoz also manages USCIS’s AWS Elastic Kubernetes Services cluster, which supports multiple mission-critical applications inside of USCIS.",
            },
            {
                type: "list",
                title: "Benefits to USCIS:",
                value: [
                    "High performance and reliability for the core applications that drive work-in-process at USCIS.",
                    "Lower operating costs through better management of cloud infrastructure across the agency.",
                    "Modern software development and operations processes – specifically tailored to operate in the Cloud and with the USCIS application portfolio",
                    "Infinite scalability through automation and the use of modern cloud tools and DevSecOps processes.",
                    "Improved technical skills of the internal team – including knowledge of the latest development tools and DevSecOps processes",
                ]
            }
        ]
    }
]
export function ClientsPartnersMenu() {

    return (

        <div className="btn-group-vertical" style={{ minWidth: '100%' }}>
            <ul style={{ minWidth: '100%' }}>
                <li className="nav-item">
                    <a className="nav-link active" href="/clients-and-partners">Clients</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/clients-and-partners">Partners</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/contracting-vehicles">Contract Vehicles</a>
                </li>
            </ul>
        </div>

    );
}
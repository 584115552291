import React, { useState, useCallback, useEffect } from 'react';
import { JsonForms } from '@jsonforms/react';
import {
    materialRenderers,
    materialCells
} from '@jsonforms/material-renderers';
import { schema, uischema } from './schemas';
import GenericModal from '../../AppComponents/GenericModal';
import FileRenderer from '../../ui/JsonForms/FileRenderer';

// Constant
import { DEFAULT_FORM_VALIDATION_MODE, FORM_VALIDATE_MODE } from '../../../constants/configuration';

// API 
import saveEntityData from '../../../apis/Entity/saveEntity';
import getEntityDataList from '../../../apis/Entity/getEntityList';

const UsersList = () => {

    const [users, setUsers] = useState([]);
    const [data, setData] = useState({});

    const [isModalVisible, setModalVisible] = useState(false);
    const handleOpenModal = () => setModalVisible(true);
    const handleCloseModal = () => setModalVisible(false);
    const [toast, setToast] = useState(false);
    const [errors, setErrors] = useState([]);
    const [validationMode, setValidationMode] = useState(DEFAULT_FORM_VALIDATION_MODE);

    // Define an async function to fetch the data
    const saveUser = useCallback(async (payload) => {
        try {
            await saveEntityData({ entityDataUrl: 'users', data: payload });
            setToast(true);
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error(error)
        }
    }, []);

    const handleSaveChanges = () => {
        // Your save logic here
        setValidationMode(FORM_VALIDATE_MODE);
        if (errors && errors.length > 0) {
            return;
        }

        const payload = {
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            avatar: data?.avatar,
            designation: data?.designation,
            title: data?.title,
        }

        saveUser(payload);
        setData({});
        setValidationMode(DEFAULT_FORM_VALIDATION_MODE);
        handleCloseModal();
    };


    // useEffect to fetch data on component mount
    useEffect(() => {
        // Define an async function to fetch the data
        const fetchData = async () => {
            try {
                // Fetch data from the API
                const response = await getEntityDataList({
                    pageNumber: 1,
                    pageSize: 10,
                    apiUrl: "users",
                });

                // Update the state with the fetched data
                setUsers(response.data);
            } catch (error) {
                // Handle any errors that occurred during the fetch
                console.error(error)
            }
        };
        // Call the fetchData function
        fetchData();
    }, []); // Empty dependency array ensures this runs only once on mount


    const downloadImage = useCallback((base64Data, fileName) => {

        if (!base64Data || base64Data.length === 0) {
            alert("No avatar found");
            return
        }

        const link = document.createElement('a');

        // Set the href attribute to the base64 data
        link.href = base64Data;

        // Set the download attribute to the desired file name
        link.download = fileName;

        // Append the link to the body (required for some browsers)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link after downloading
        document.body.removeChild(link);
    }, [])

    return (
        <div className="container p-4">
            <div>
                <button className="btn btn-small btn-gradient btn-rounded"
                    onClick={handleOpenModal}>
                    Add User
                </button>
            </div>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Designation</th>
                        <th scope="col">Title</th>
                        <th scope="col">Avatar</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(users) && users.length > 0 && users?.map((user, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td style={{ textWrap: "nowrap" }}>{`${user?.firstName} ${user?.lastName}` || "-"}</td>
                            <td>{user?.email || "-"}</td>
                            <td>{user?.designation || "-"}</td>
                            <td>{user?.title || "-"}</td>
                            <td>
                                <h5>
                                    <i className="fa fa-download gradient-text1" aria-hidden="true"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => downloadImage(user?.avatar, `${user?.firstName}-${user?.lastName}.png`)} ></i>
                                </h5>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <GenericModal
                show={isModalVisible}
                title="Create New User"
                onClose={handleCloseModal}
                onSave={handleSaveChanges}
            >
                <JsonForms
                    schema={schema}
                    uischema={uischema}
                    renderers={[
                        ...materialRenderers,
                        { tester: (uiSchema) => (uiSchema.options && uiSchema.options.fileRenderer ? 3 : -1), renderer: FileRenderer } // Register the custom renderer
                    ]}
                    cells={materialCells}
                    validationMode={validationMode}
                    data={data}
                    onChange={({ errors, data }) => { setData(data); setErrors(errors); }}
                />
            </GenericModal>
            <div class="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: 1200 }}>
                <div id="liveToast" className={toast ? 'toast show' : 'toast'} role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header">
                        <strong class="me-auto">User create</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setToast(false)}></button>
                    </div>
                    <div class="toast-body">
                        User saved successfully!
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersList;

// export function UserCreate() {
//     const [data, setData] = useState({});
//     const [errors, setErrors] = useState([]);
//     const [toast, setToast] = useState(false);
//     const [validationMode, setValidationMode] = useState(DEFAULT_FORM_VALIDATION_MODE);
//     // Define an async function to submit the data
//     const submitData = useCallback(async (event, payload) => {

//         event.preventDefault();

//         // set validation mode to validate on save
//         setValidationMode(FORM_VALIDATE_MODE);

//         // check if there are any errors
//         if (errors && errors.length > 0) {
//             return;
//         }

//         try {
//             await saveEntityData({ entityDataUrl: 'users', data: payload });
//             setToast(true);
//             setData({});
//             setErrors([]);
//             setValidationMode(DEFAULT_FORM_VALIDATION_MODE);
//         } catch (error) {
//             // Handle any errors that occurred during the fetch
//             console.error(error)
//         }
//     }, [setToast, errors]);

//     return (
//         <section className="career-section">
//             <div className="container">
//                 <h3 className="gradient-text1 mb-4">Create new user</h3>
//                 <form onSubmit={(event) => submitData(event, data)}>
//                     <JsonForms
//                         schema={schema}
//                         uischema={uischema}
//                         renderers={materialRenderers}
//                         cells={materialCells}
//                         validationMode={validationMode}
//                         data={data}
//                         onChange={({ errors, data }) => { setData(data); setErrors(errors); }}
//                     />
//                     <button type="submit"
//                         className="btn btn-large btn-gradient btn-rounded mt-4">
//                         Submit
//                     </button>
//                 </form>
//             </div>

//             <div class="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: 1200 }}>
//                 <div id="liveToast" className={toast ? 'toast show' : 'toast'} role="alert" aria-live="assertive" aria-atomic="true">
//                     <div class="toast-header">
//                         <strong class="me-auto">User create</strong>
//                         <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setToast(false)}></button>
//                     </div>
//                     <div class="toast-body">
//                         User saved successfully!
//                     </div>
//                 </div>
//             </div>
//         </section>
//     );
// }
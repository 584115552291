// import keycloak from '../keycloak';
import cookie from './Cookie';

const Authenticator = {
    login: (keycloak) => {
        if (!cookie.readCookie('redirectUri')) {
            cookie.createCookie('redirectUri', window.location.pathname, 1);
        }
        keycloak.login({ redirectUri: process.env.REACT_APP_UI_BASE + 'security/doneLogin' || 'http://localhost:3020/security/doneLogin' });
        // keycloak.login();
    },
    setAuthenticated: (keycloak) => {
        let redirectUri = cookie.readCookie('redirectUri') || '/';
        const isLoggedIn = keycloak.authenticated;
        if (isLoggedIn) {
            if (keycloak?.token)
                cookie.createCookie('accessToken', keycloak.token, 24);

            if (keycloak?.tokenParsed) {
                cookie.createCookie('tokenParsed', JSON.stringify(keycloak.tokenParsed), 24);
                cookie.createCookie('userName', keycloak.tokenParsed.preferred_username, 24);
                cookie.createCookie('givenName', keycloak.tokenParsed.given_name, 24);
            }

            cookie.createCookie('isLoggedIn', true, 24);
        }

        //  redirect to the page that was requested before login
        if (redirectUri) {
            cookie.removeCookie('redirectUri');
            window.location.href = redirectUri;
        }
    },
    logout: (keycloak) => {
        cookie.removeCookie('accessToken');
        cookie.removeCookie('tokenParsed');
        cookie.removeCookie('userName');
        cookie.removeCookie('givenName');
        cookie.removeCookie('isLoggedIn');
        cookie.removeCookie('redirectUri');
        keycloak.logout({ redirectUri: process.env.REACT_APP_UI_BASE || 'http://localhost:3020' });
        // window.location.href = '/';
    }
};

export default Authenticator;
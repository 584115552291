import React, { useState, useCallback, useEffect } from 'react';
import { JsonForms } from '@jsonforms/react';
import {
    materialRenderers,
    materialCells

} from '@jsonforms/material-renderers';

import { schema, uischema } from './schema';

import GenericModal from '../GenericModal';

// Constant
import { DEFAULT_FORM_VALIDATION_MODE, FORM_VALIDATE_MODE } from '../../../constants/configuration';

// API
import sendEBookMail from '../../../apis/sendEbookMail';

export default function Ebook() {

    const [data, setData] = useState({});
    const [errors, setErrors] = useState([]);
    const [validationMode, setValidationMode] = useState(DEFAULT_FORM_VALIDATION_MODE);

    const [toast, setToast] = useState(false);

    const [isModalVisible, setModalVisible] = useState(false);
    const handleOpenModal = () => setModalVisible(true);
    const handleCloseModal = () => setModalVisible(false);

    const handleChange = ({ data, errors }) => {
        setErrors(errors);
        setData(data);
    }

    // Define an async function to fetch the data
    const sendEbook = useCallback(async (payload) => {
        try {
            await sendEBookMail(payload);
            setToast(true);
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error(error)
        }
    }, []);


    const handleSaveChanges = () => {
        // Your save logic here
        setValidationMode(FORM_VALIDATE_MODE);
        if (errors && errors.length > 0) {
            return;
        }

        const payload = {
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
        }

        sendEbook(payload);
        setData({});
        setValidationMode(DEFAULT_FORM_VALIDATION_MODE);
        handleCloseModal();
    };



    return (
        <section className="bg-light content">
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 wow ">
                        <div className="title">
                            <h6 className="third-color mb-3">Ebook</h6>
                            <h2 className="mb-4">
                                The Continuous<br />
                                Modernization Imperative</h2>
                            <p>Download the Ebook to learn more about Optimoz's capabilities.</p>
                            <button className="mt-4 btn btn-small btn-gradient btn-rounded"
                                onClick={handleOpenModal}>
                                Download
                            </button>
                        </div>
                    </div>


                    <div className="col-lg-6 wow ">
                        <div className="content-image">
                            <img src="images/site/optimoz-ebook.png" alt="image" />
                        </div>
                    </div>
                </div>
            </div>

            <GenericModal
                show={isModalVisible}
                title="Modernization Imperative Handbook"
                saveButtonText='Send Mail'
                onClose={handleCloseModal}
                onSave={handleSaveChanges}
            >
                <JsonForms
                    schema={schema}
                    uischema={uischema}
                    renderers={materialRenderers}
                    cells={materialCells}
                    data={data}
                    validationMode={validationMode}
                    onChange={handleChange}
                />
            </GenericModal>
            <div class="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: 1200 }}>
                <div id="liveToast" className={toast ? 'toast show' : 'toast'} role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header">
                        <strong class="me-auto">Ebook Mail Sent</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setToast(false)}></button>
                    </div>
                    <div class="toast-body">
                        Thank you for your interest.
                    </div>
                </div>
            </div>
        </section>
    );
}
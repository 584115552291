import React from "react";
import { withJsonFormsControlProps } from '@jsonforms/react';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextEditor = ({ data, handleChange, path, uischema }) => {
  const modules = {
    toolbar: {
      container: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }]
      ]
    }
  };
  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      placeholder={uischema?.options?.placeholder || "Write something..."}
      value={data || ''}
      onChange={(data) => handleChange(path, data)}
    />
  );
};

export default withJsonFormsControlProps(RichTextEditor);
export const schema = {
    "title": "Contact Form",
    "type": "object",
    "required": [
        "name",
        "email",
        "subject",
        "message",
    ],
    "properties": {
        "name": {
            "title": "Name",
            "type": "string",
            "default": ""
        },
        "email": {
            "title": "Email",
            "type": "string",
            "default": ""
        },
        "phoneNumber": {
            "type": "number",
            "title": "Phone Number"
        },
        "subject": {
            "type": "string",
            "title": "Subject"
        },
        "message": {
            "type": "string",
            "title": "Message"
        }
    }
}
export const uischema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "Control",
            "scope": "#/properties/name",
            "label": "Name",
            "options": {
                "placeholder": "Enter name",
            }
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/email",
                    "label": "Email",
                    "options": {
                        "placeholder": "Enter email",
                    }
                },

                {
                    "type": "Control",
                    "scope": "#/properties/phoneNumber",
                    "label": "Phone Number",
                    "options": {
                        "placeholder": "Enter phone number",
                    }

                },
            ]
        },
        {
            "type": "Control",
            "scope": "#/properties/subject",
            "label": "Subject",
            "options": {
                "placeholder": "Enter subject",
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/message",
            "label": "Message",
            "options": {
                "placeholder": "Enter message",
                "multi": true
            }

        },

    ]
}
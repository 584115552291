export const schema = {
    "title": "Blog Post",
    "type": "object",
    "required": [
        "firstName",
        "lastName"
    ],
    "properties": {
        "firstName": {
            "type": "string",
            "title": "First name"
        },
        "lastName": {
            "type": "string",
            "title": "Last name"
        },
        "jobId": {
            "type": "string",
            "title": "Job ID",
        },
        "email": {
            "type": "string",
            "title": "Email"
        },
        "telephone": {
            "pattern": "\\d+",
            "type": "string",
            "title": "Phone Number",
            "minLength": 10
        },
        "location": {
            "type": "string",
            "title": "Current Location",
            "enum": [
                "Rockville, MD",
                "Ahmedabad, GJ",
                "Remote"
            ]
        },
        "veteranStatus": {
            "type": "string",
            "title": "Veteran Status",
            "enum": [
                "Veteran",
                "Not a Veteran",
                "I prefer not to say"
            ]
        },
        "disabilityStatus": {
            "type": "string",
            "title": "Disability",
            "enum": [
                "Disabled",
                "Not Disabled",
                "I prefer not to say"
            ]
        },
        "githubURL": {
            "type": "string",
            "format": "uri",
            "title": "GitHub"
        },
        "linkedinURL": {
            "type": "string",
            "format": "uri",
            "title": "LinkedIn"
        },
        "resume": {
            "type": "string",
            "format": "data-url",
            "title": "Upload File"
        },
        "agreedTerms": {
            "type": "boolean",
            "title": "I agree to the terms and conditions."
        }
    }
}
export const uischema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/firstName",
                    "label": "FirstName",
                    "options": {
                        "placeholder": "Enter the first name",
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/lastName",
                    "label": "LastName",
                    "options": {
                        "placeholder": "Enter the last name",
                    }
                },
            ]
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/email",
                    "label": "Email Address",
                    "options": {
                        "placeholder": "Enter the email address",
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/telephone",
                    "label": "Phone Number",
                    "options": {
                        "placeholder": "Enter the phone number",
                    }
                },
            ]
        },

        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/location",
                    "label": "Location",
                    "options": {
                        "placeholder": "Select the location",
                        "format": "dropdown"
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/veteranStatus",
                    "label": "Veteran Status",
                    "options": {
                        "placeholder": "Select the veteran status ",
                        "format": "dropdown"
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/disabilityStatus",
                    "label": "Disability Status",
                    "options": {
                        "placeholder": "Select the disability status",
                        "format": "dropdown"
                    }
                },
            ]
        },
        {
            "type": "Control",
            "scope": "#/properties/githubURL",
            "label": "GitHub URL",
            "options": {
                "placeholder": "Enter the github url",
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/linkedinURL",
            "label": "Linkedin URL",
            "options": {
                "placeholder": "Enter the linkedin url",
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/resume",
            "label": "Upload Resume",
            "options": {
                "fileRenderer": "file",
                "accept": ".pdf",
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/agreedTerms",
            "label": "Agree Terms and Conditions",
            "options": {
                "placeholder": "Enter the linkedin url",
            }
        }
    ]
}
// API URLs based on env
const apiBaseURL = process.env.REACT_APP_APIBASE || 'http://localhost:29514/api/o5z-www-api/';

// Common Configuration related to API
export const CONFIG = {
    env: process.env.REACT_APP_APP_ENV || 'staging',
    timeout: 100000,
    reportTimeout: 20000,
};


// Function to get api url based on env
export const getApiBase = (version = 'v1') => {
    return ''.concat(apiBaseURL).concat(version);
};



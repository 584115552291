import NavbarPages from '../../../components/AppComponents/NavbarPages';
import HeaderPages from '../../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../../components/AppComponents/FooterMain';
import ContactInfo from '../../../components/AppComponents/ContactInfo';
import Applicants from '../../../components/admin/Applicants';
const ApplicantsPage = () => {

    return (
        <div>
            <NavbarPages />
            <HeaderPages title={"Job Applicants"} subTitle={"Applicants List"} />
            <Applicants />
            <ContactInfo />
            <FooterMain />
        </div>
    );
}

export default ApplicantsPage
import React, { useState, useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { NavLink } from "react-router-dom";
import validateToken from "../../../helpers/TokenValidator";
import { Navbar, Nav } from 'react-bootstrap';

const CustomToggle = React.forwardRef(({ children, onClick, isOpen }, ref) => (
    <a
        className="nav-link scroll1"
        href="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation(); // Stop event from bubbling up
            onClick(e);
        }}
        style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            fontSize: '1.4rem',
            cursor: 'pointer'
        }}
    >
        {children}
        {isOpen ? (
            <ArrowDropUpIcon style={{ marginLeft: 8 }} />
        ) : (
            <ArrowDropDownIcon style={{ marginLeft: 8 }} />
        )}
    </a>
));


export default function SideNavMenu() {
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null); // Reference to the dropdown for detecting outside clicks

    // Toggle dropdown open/close state
    const handleToggle = () => {
        setOpen((prevState) => !prevState); // Toggle the dropdown state
    };

    // Handle item selection and close dropdown
    const handleSelect = () => {
        setOpen(false); // Close dropdown when an item is selected
    };

    // Close dropdown when clicking outside of it
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false); // Close the dropdown
        }
    };

    // Attach the click outside handler
    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>

            <div className="side-menu">
                <div className="inner-wrapper">
                    <span className="btn-close" id="btn_sideNavClose"><i></i><i></i></span>
                    <Navbar variant="dark" expand="lg" style={{
                        transition: 'top 0.3s',
                        fontSize: '1.4rem', // Increase font size
                    }} >
                        <Nav className="me-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/about">About</Nav.Link>

                            {/* Custom Dropdown */}
                            <div ref={dropdownRef}>
                                <Dropdown show={open}>
                                    <Dropdown.Toggle as={CustomToggle} onClick={handleToggle}>
                                        Solutions
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item className='side-nav-link' href="https://optalk.ai" target="_blank" onClick={handleSelect}>
                                            optalk.ai
                                        </Dropdown.Item>
                                        <Dropdown.Item href="/healthit" onClick={handleSelect}>
                                            Healthcare IT
                                        </Dropdown.Item>
                                        <Dropdown.Item href="/cloud-engineering" onClick={handleSelect}>
                                            Cloud Engineering
                                        </Dropdown.Item>
                                        <Dropdown.Item href="/devsecops" onClick={handleSelect}>
                                            DevSecOps
                                        </Dropdown.Item>
                                        <Dropdown.Item href="/software-engineering" onClick={handleSelect}>
                                            Software Engineering
                                        </Dropdown.Item>
                                        <Dropdown.Item href="/data-management" onClick={handleSelect}>
                                            Data Management
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            <Nav.Link href="/capabilities">Capabilities</Nav.Link>
                            <Nav.Link className='sub-nav-link' href="/optalk">optalk.ai</Nav.Link>
                            <Nav.Link href="/clients-and-partners">Clients & Partners</Nav.Link>
                            <Nav.Link href="/careers">Careers</Nav.Link>
                            <Nav.Link href="/blogs">Insights</Nav.Link>
                            <Nav.Link href="/contact">Contact</Nav.Link>
                            {validateToken() ? (
                                <NavLink className="nav-link" to="/logout">
                                    Logout
                                </NavLink>
                            ) : (
                                <NavLink className="nav-link" to="/login">
                                    Login
                                </NavLink>
                            )}
                        </Nav>
                    </Navbar>

                    <div className="side-footer text-white w-100">
                        <ul className="social-icons-simple">
                            <li><a className="facebook-text-hvr" href="https://www.facebook.com/optimozinc"><i className="fa fa-facebook"></i> </a> </li>
                            <li><a className="linkedin-text-hvr" href="https://www.linkedin.com/company/optimozinc"><i className="fa fa-linkedin"></i> </a> </li>
                            <li><a className="twitter-text-hvr" href="https://twitter.com/optimoz1"><i className="fa fa-twitter"></i> </a> </li>
                        </ul>
                        <p className="text-white">&copy; 2024 Optimoz, Inc.</p>
                    </div>
                </div>
            </div>
            <a id="close_side_menu" href="javascript:void(0);"></a>
        </div>
    );
}
import React, { useEffect } from 'react';
import  authenticator from '../../helpers/Authenticator';
import { useKeycloak } from '@react-keycloak/web';
const DoneLoginPage = () => {
  console.log('DoneLoginPage');
  
  const {keycloak, initialized} = useKeycloak();

  useEffect(() => {
    console.log(initialized)
    if (!initialized) {
      return;
    }
    authenticator.setAuthenticated(keycloak);
  }, [initialized, keycloak]);

  return (
    <div>
    </div>
  );
};

export default DoneLoginPage;
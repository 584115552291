import getAxiosInstance from './getAxiosInstance';

// API call for get entity data
const sendEBookMail = async (apiPayload) => {


    if (!apiPayload && Object.keys(apiPayload).length === 0) {
        return Promise.reject({
            error: true,
            message: 'Payload Data should not be null',
        });
    }

    const instance = getAxiosInstance();

    return instance.post(`/ebook/send`, apiPayload);
};

export default sendEBookMail;

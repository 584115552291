import ProductsServices from "../ProductsServices";

export function Healthit() {
    return (
        <section id="products-and-services" className="bg-light wm-bg-section">
            <div className="container">
                {/* Heading */}
                <div className="row">
                    <div className="col-md-12 text-center wow ">
                        <div className="title d-inline-block">
                            <h6 className="mb-3">Transforming the public health IT</h6>
                            <h2 className="gradient-text1">Trusted Ally in Advancing Public Health</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-left wow ">
                        <p>Federal and state agencies provide healthcare services and health information to constituents nationwide. As the healthcare system becomes more complex, healthcare agencies face an expanding set of challenges.  Optimoz leverages its entire suite of technical capabilities to provide healthcare IT solutions that help agencies improve public health and provide better services to constituents.</p>
                        <p className="mb-3">Optimoz delivers four types of healthcare IT solutions:</p>

                        <p className="mb-3"><strong className="gradient-text1">Data Management and Interoperability Solutions</strong> - focused on the HL7 data standard used across the healthcare industry - enabling federal and state agencies, healthcare systems, and constituents to more easily access healthcare data to serve constituents.</p>
                        <p className="mb-3"><strong className="gradient-text1">Portals</strong> - that provide constituents with information on healthcare services and their own personal care.</p>
                        <p className="mb-3"><strong className="gradient-text1">Healthcare Information Systems</strong> - that serve as repositories and a single source of truth for critical public health data related to immunizations and behavioral and population health.</p>
                        <p className="mb-3"><strong className="gradient-text1">Custom Application Development and O&M support</strong> - for systems of record that support the work of federal and state health agencies.</p>

                        <img src="/images/optimoz/healthit-solutions.gif" alt="Health IT Solutions" />

                        <p><strong>Our services include:</strong></p>

                        <p><strong className="gradient-text1">Technology Evaluation and Selection:</strong> In any software development effort, several key technology choices need to be made based on the desired outcome, cost of delivery, skills of the current and desired team, and user expectations. These choices include:</p>
                        <ul className="packages">
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Cloud platform selection</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Cloud infrastructure components</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Programming language</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Databases</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>AI/ML components</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Analytics and reporting tools</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Third-party components, such as microservices</li>
                        </ul>

                        <p className="mb-3">We do the research and make fact-based recommendations on the technologies and approaches that will be suit your needs.</p>

                        <p className="mb-3"><strong className="gradient-text1">Product Management</strong> - We work with your enterprise to bring new products to market. We sit at the intersection of the business, technology, and user experience.  We work with your team to determine your requirements and prioritize features for the initial and subsequent releases based on customer feedback, the state of the market, and the technical requirements of the application.</p>

                        <p className="mb-3"><strong className="gradient-text1">Application Architecture</strong> - We work with your enterprise to make the architectural decisions that will drive the performance of an application, including</p>

                        <ul className="packages">
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Selecting the structural components and interfaces to use in the application.</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Determining the expected interfaces between those components.</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Configuring selected components into appropriate subsystems.</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Defining the interfaces between components.</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Aligning architectural decisions with business requirements.</li>
                        </ul>

                        <p className="mb-3"><strong className="gradient-text1">Software Engineering</strong> - We put in place and manage software development teams that can get the job done. Our teams typically include application architects, senior and mid-level developers, AI/ML experts, data analysts, QA engineers, DevSecOps professionals. We can either provide a full team or work as part of a blended team - providing specific technical skills and project leadership, as needed.</p>

                        <p className="mb-3"><strong className="gradient-text1">DevSecOps & CI/CD</strong> - We put in places practices to improve your software development processes, better integrate your technical teams, supercharge the performance of your applications and infrastructure, improve security, and ensure that software can be released on-time with very high quality.</p>

                        <p className="mb-3"><strong className="gradient-text1">Project Management</strong> - Many work streams must come together to deliver a quality software application on time and on budget. We work with your team to project manage your software development project to completion.</p>

                    </div>
                </div>

            </div>
        </section>
    );
}
import SideNavMenu from "../SideNavMenu";
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useIsMobile } from "../../../helpers/DeviceDetect";
import NavbarMain from "../NavbarMain";

export default function NavbarPages() {
    const variant = 'navbar-dark';

    return (
        <NavbarMain variant={variant}/>
    );
}

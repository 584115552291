import { useCallback } from "react";
import DOMPurify from "dompurify";
import { CalendarToday } from "@mui/icons-material";
import { BLOG_DEFAULT_AUTHOR_NAME } from "../../../constants/configuration";
const BlogContent = (props) => {

    const formateDate = useCallback((date) => {
        if (date) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        }
        return "";
    }, [])

    const {
        blogTitle,
        blogContent,
        author = BLOG_DEFAULT_AUTHOR_NAME,
        publishedOn,
        blogPicture,
        blogExcerpt,
        avatar,
        designation
    } = props;


    const sanitizedHTML = DOMPurify.sanitize(blogContent);
    const sanitizedHTMLexcerpt = DOMPurify.sanitize(blogExcerpt);

    return (
        <div className="blog-content-container container">

            <div className="p-4">
                <div className="py-3">
                    <a href="/blogs" class="btn btn-sm btn-link p-0 active" role="button" aria-pressed="true">Back to blogs</a>
                </div>
                <div className="blog-title mb-4 d-flex flex-row justify-content-between">
                    <div>
                        <h1 className="h3 mb-2">{blogTitle}</h1>
                        <div className="d-flex flex-row gap-2 align-items-center mb-3"> <CalendarToday fontSize="small" /><span className="small lh-1 fst-italic">  {formateDate(publishedOn)}</span></div>
                    </div>
                    <div className="d-flex flex-row gap-2 align-items-center rounded p-2">
                        <img style={{ "objectFit": "contain" }} src={avatar} height={45} width={45} class="rounded float-left" alt="Author" ></img>
                        <div className="d-flex flex-column">
                            <span className="lh-base">{author}</span>
                            <span className="small lh-1 fst-italic">{designation}</span>
                        </div>
                    </div>
                </div>
                <div className="blog-content">
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: blogExcerpt }}></div>
                        {blogPicture && <div className="d-flex justify-content-center"><img style={{ "objectFit": "contain", }} src={blogPicture} height={500} width={500} class="rounded float-left" alt="blog-image" ></img></div>}

                    </div>
                    <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
                </div>
            </div>
        </div>
    );
};

export default BlogContent
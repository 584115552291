import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import { Healthit } from '../../components/AppComponents/Healthit';

const HealthitPage = () => {
  const title = "Health IT";
  const subTitle = "Transforming the public health IT";
  return (
    <div>
      <NavbarPages />
      <HeaderPages title={title} subTitle={subTitle} />
      <Healthit />
      <FooterMain />
    </div>
  );
};

export default HealthitPage;
export const schema = {
    "title": "Users",
    "type": "object",
    "required": [
        "firstName",
        "lastName",
        "email",
        "designation",
        "title",
        "avatar",
    ],
    "definitions": {
    },
    "properties": {
        "firstName": {
            "title": "First Name",
            "type": "string",
        },
        "lastName": {
            "type": "string",
            "title": "Last Name",
        },
        "email": {
            "type": "string",
            "title": "Email"
        },
        "title": {
            "type": "string",
            "title": "Title"
        },
        "designation": {
            "type": "string",
            "title": "Designation",
            "enum": [
                "Software Engineer",
                "Business Development Executive",
                "Marketing Manager",
                "HR Manager",
                "Sales Manager",
                "Project Manager",
                "Product Manager",
                "Chief Executive Officer",
                "Chief Technical Officer",
                "Chief Financial Officer",
                "Chief Operating Officer",
            ]
        },
        "avatar": {
            "type": "string",
            "format": "data-url",
            "title": "Avatar"
        },
    }
}
export const uischema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/firstName",
                    "label": "First Name",
                    "options": {
                        "placeholder": "Enter the First name",
                        "focus": true
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/lastName",
                    "label": "Last Name",
                    "options": {
                        "placeholder": "Enter the Last name",
                        "focus": true
                    }
                }
            ]
        },
        {
            "type": "Control",
            "scope": "#/properties/email",
            "label": "Email Address",
            "options": {
                "placeholder": "Enter email address",
                "focus": true
            }
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/designation",
                    "label": "Designation",
                    "options": {
                        "format": "dropdown"
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/title",
                    "label": "Title",
                    "options": {
                        "placeholder": "Enter title",
                        "focus": true
                    }
                }
            ]
        },
        {
            "type": "Control",
            "scope": "#/properties/avatar",
            "label": "Upload Avatar Picture",
            "options": {
                "fileRenderer": "file",
                "accept": ".png .jpg .jpeg",
            }
        },

    ]
}
import React from 'react';
import NavbarPages from '../../../components/AppComponents/NavbarPages';
import HeaderPages from '../../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../../components/AppComponents/FooterMain';
import ContactInfo from '../../../components/AppComponents/ContactInfo';
import { BlogPost } from '../../../components/admin/BlogPost';

const BlogPostPage = () => {
  const title = "Blog Post";
  const subTitle = "Post a new blog";

  return (
    <div width="50%">
      <NavbarPages />
      <HeaderPages title={title} subTitle={subTitle} />
      <BlogPost />
      <ContactInfo />
      <FooterMain />
    </div>
  );
};

export default BlogPostPage;
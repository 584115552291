import { useCallback, useEffect, useState } from "react";
// API
import getEntityDataList from "../../../apis/Entity/getEntityList";

const Applicants = () => {


    const [applicants, setApplicants] = useState([]);
    // useEffect to fetch data on component mount
    useEffect(() => {
        // Define an async function to fetch the data
        const fetchData = async () => {
            try {
                // Fetch data from the API
                const response = await getEntityDataList({
                    pageNumber: 1,
                    pageSize: 10,
                    apiUrl: "applicants",
                });

                // Update the state with the fetched data
                setApplicants(response.data);
            } catch (error) {
                // Handle any errors that occurred during the fetch
                console.error(error)
            }
        };
        // Call the fetchData function
        fetchData();
    }, []); // Empty dependency array ensures this runs only once on mount


    const downloadResume = useCallback((base64Data, fileName) => {

        if (!base64Data || base64Data.length === 0) {
            alert("No resume found");
            return
        }

        const link = document.createElement('a');

        // Set the href attribute to the base64 data
        link.href = base64Data;

        // Set the download attribute to the desired file name
        link.download = fileName;

        // Append the link to the body (required for some browsers)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link after downloading
        document.body.removeChild(link);
    }, [])

    return (
        <div className="container p-4">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">location</th>
                        <th scope="col">Veteran Status</th>
                        <th scope="col">Disability Status</th>
                        <th scope="col">Github URL</th>
                        <th scope="col">Linkedin URL</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(applicants) && applicants.length > 0 && applicants?.map((applicant, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td style={{ textWrap: "nowrap" }}>{`${applicant?.firstName} ${applicant?.lastName}` || "-"}</td>
                            <td>{applicant?.email || "-"}</td>
                            <td>{applicant?.phone || "-"}</td>
                            <td>{applicant?.location || "-"}</td>
                            <td>{applicant?.veteranStatus || "-"}</td>
                            <td>{applicant?.disabilityStatus || "-"}</td>
                            <td>{applicant?.githubURL || "-"}</td>
                            <td>{applicant?.linkedinURL || "-"}</td>
                            {/* <td>
                                <button className="btn btn-sm btn-primary btn-small btn-gradient btn-rounded" onClick={() => window.location.href = `/job/view/${applicant?.jobId}`}><span style={{ fontSize: "12px", textWrap: "nowrap" }} className="small">view job</span></button></td>
                            </td> */}
                            <td>
                                {/* <button className="btn btn-sm btn-info btn-small btn-gradient btn-rounded" onClick={() => downloadResume(applicant?.resume, `${applicant?.firstName}-${applicant?.lastName}.pdf`)}><span style={{ fontSize: "12px", textWrap: "nowrap" }} className="small text-white">Download Resume</span></button> */}
                                <h5>
                                    <i className="fa fa-eye gradient-text1" aria-hidden="true"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => window.location.href = `/job/view/${applicant?.jobId}`} ></i>
                                    &nbsp;&nbsp;
                                    <i className="fa fa-download gradient-text1" aria-hidden="true"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => downloadResume(applicant?.resume, `${applicant?.firstName}-${applicant?.lastName}.pdf`)} ></i>
                                </h5>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Applicants
export function FooterMain() {
    return (
        <section className="bg-white text-center p-0">
            <h2 className="d-none">hidden</h2>
            <div className="container">
                <div className="row">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="footer-social">
                            <ul className="list-unstyled">
                                <li><a className="wow " href="https://www.facebook.com/optimozinc"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li><a className="wow " href="https://twitter.com/optimoz1"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a className="wow " href="https://www.linkedin.com/company/optimozinc"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                        <p className="company-about ">&copy; 2024 Optimoz, Inc.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
// import axios from 'axios';
// import jwt from 'jsonwebtoken';
import { jwtDecode } from "jwt-decode";
import Cookie from "./Cookie";


// Function to get the public key from the JWKS endpoint
// const getPublicKey = async () =>  {
//     const jwksUrl = 'https://sso-dev.singlenum.com/auth/realms/singlenum/protocol/openid-connect/certs';
//     const response = await axios.get(jwksUrl);
//     console.log(response)
//     const publicKey = `-----BEGIN PUBLIC KEY-----\
// ${response.data.keys[0].x5c[0]}\
// -----END PUBLIC KEY-----`;
//     return publicKey;
// }

// Function to validate the JWT token
const validateToken = (token) => {
    try {
        const token = Cookie.readCookie('accessToken');
        if (!token) {
            return false;
        }
        const decoded = jwtDecode(token);
        // check if the token is expired
        if (decoded.exp < Date.now() / 1000) {
            console.error('Token is expired');
            return false;
        }
        return true;
    } catch (err) {
        console.error('Token is invalid:', err);
        return false;
    }
}

export default validateToken;


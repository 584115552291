import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';

const DevSecOpsPage = () => {
    const title = "DevSecOps";
    const subTitle = "Swift Dev, Safe & Secure";
    return (
        <div>
            <NavbarPages />
            <HeaderPages title={title} subTitle={subTitle} />
            <section id="dev-sec-ops" className="bg-light wm-bg-section">
                <div className="container">
                    {/* Heading */}
                    <div className="row">
                        <div className="col-md-12 text-center wow ">
                            <div className="title d-inline-block">
                                <h6 className="mb-3">Boost Dev Speed & Security</h6>
                                <h2 className="gradient-text1">CI/CD, Automation & Security Tools</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-left wow ">
                            <p>For most enterprises, building, operating, and supporting software applications takes too long, costs too much, and doesn’t meet the high expectations of stakeholders or end-users. Optimoz works with your enterprise to implement DevSecOps practices to improve your application development processes and supercharge the performance of your enterprise applications in operation.  </p>
                            <p className="my-2"> Our capabilities include:</p>
                            <p className="mb-3"><strong className="gradient-text1">DevSecOps </strong> emphasizes communication, collaboration, and cohesion between the developer, IT operations, and security teams.  By integrating these teams, your enterprise can deploy software more frequently, maintain service stability, and promote innovation. DevSecOps inserts a focus on security into every role and process involved in building, updating, and maintaining applications.</p>
                            <p className="mb-3"><strong className="gradient-text1">Continuous Integration / Continuous Delivery (CI/CD)</strong> is a software engineering approach, where teams work together to produce software in short cycles and then ensure that the software can be reliably released at any time. A successful CI/CD process connects the work of all the groups involved in software development – product management, architecture, software development, QA, DevSecOps, Operations, and Support.  The result is an efficient, repeatable, cost effective, and high-quality set of processes that deliver consistently great applications.</p>

                            <div className='d-flex p-5 justify-content-center '>
                                <img style={{ width: '70%' }} src="/images/optimoz/dev-ops-pipeline.png" alt="DevOps Pipeline" />
                            </div>
                            <p className="mt-4"><strong>Our DevSecOps approach is divided into four phases:</strong></p>

                            <ul className="packages">
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Assess</strong> - Establish clear criteria for improving the product delivery lifecycle.</li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Design </strong> - Understand what needs to be optimized to create a business advantage in product delivery.</li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Implement </strong> -  implement a right-sized Continuous Delivery process designed around in-house capabilities and needs.</li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i><strong>Operate </strong> - Create an operational advantage based on repeatability, flexibility, and fast cycle times.</li>
                            </ul>

                            <p className="mt-4"><strong>Working with Optimoz, enterprises can use DevSecOps and CI/CD to: </strong></p>

                            <ul className="packages">
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Set & meet expectations with stakeholders and users</li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Measure progress</li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Deliver fast results</li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Increase reliability</li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Improve security</li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Lower development and operations costs</li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Become more competitive</li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Lower risk</li>
                                <li><i className="fa fa-circle-o" aria-hidden="true"></i>Create competitive advantage</li>
                            </ul>



                        </div>
                    </div>

                </div>
                <div className='d-flex p-5 justify-content-center'>
                    <img style={{ width: '60%' }} src="/images/optimoz/security.png" alt="Health IT Solutions" />
                </div>
            </section>
            <FooterMain />
        </div>
    );
};

export default DevSecOpsPage;
import { useCallback, useState } from "react";
import DOMPurify from "dompurify";
import GenericModal from "../GenericModal";
import { JsonForms } from "@jsonforms/react";
import { uischema, schema } from "./schema"
import {
    materialRenderers,
    materialCells
} from '@jsonforms/material-renderers';
import FileRenderer from "../../ui/JsonForms/FileRenderer";
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { LocationOn } from "@mui/icons-material";



// Constant
import { DEFAULT_FORM_VALIDATION_MODE, FORM_VALIDATE_MODE } from "../../../constants/configuration";

// API
import saveEntityData from "../../../apis/Entity/saveEntity";

const JobContent = (props) => {

    const formateDate = useCallback((date) => {
        if (date) {
            return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        }
        return "";
    }, [])

    const [data, setData] = useState({});
    const [errors, setErrors] = useState([]);
    const [toast, setToast] = useState(false);
    const [validationMode, setValidationMode] = useState(DEFAULT_FORM_VALIDATION_MODE);

    // Define an async function to fetch the data
    const saveApplicant = useCallback(async (payload) => {
        try {
            await saveEntityData({ entityDataUrl: 'applicants', data: payload });
            setToast(true);
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error(error)
        }
    }, []);

    const [isModalVisible, setModalVisible] = useState(false);
    const handleOpenModal = () => setModalVisible(true);
    const handleCloseModal = () => setModalVisible(false);
    const handleSaveChanges = () => {
        // Your save logic here
        setValidationMode(FORM_VALIDATE_MODE);
        if (errors && errors.length > 0) {
            return;
        }

        const payload = {
            firstName: data?.firstName,
            jobId: props?.jobId,
            lastName: data?.lastName,
            email: data?.email,
            telephone: data?.telephone,
            resume: data?.resume,
            agreedTerms: data?.agreedTerms,
            location: data?.location,
            veteranStatus: data?.veteranStatus,
            disabilityStatus: data?.disabilityStatus,
            githubURL: data?.githubURL,
            linkedinURL: data?.linkedinURL,
        }

        saveApplicant(payload);
        setData({});
        setValidationMode(DEFAULT_FORM_VALIDATION_MODE);
        handleCloseModal();
    };

    const handleChange = ({ data, errors }) => {
        setErrors(errors);
        setData(data);
    }

    const {
        datePosted,
        educationLevel,
        jobLocation,
        jobResponsibilities,
        jobTitle,
        status,
    } = props;


    const renderers = [
        ...materialRenderers,
        { tester: (uiSchema) => (uiSchema.options && uiSchema.options.fileRenderer ? 3 : -1), renderer: FileRenderer }  // Register the custom renderer
    ];

    const sanitizedHTML = DOMPurify.sanitize(jobResponsibilities);

    return (
        <div className="blog-content-container container">

            <div className="p-4">
                <div className="py-3">
                    <a href="/careers" class="btn btn-sm btn-link p-0 active" role="button" aria-pressed="true">Back to Career Page</a>
                </div>
                <div className="blog-title">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h1 className="h3 mb-2">{jobTitle}</h1>
                        </div>
                        <div>
                            <button className="btn btn-small btn-gradient btn-rounded"
                                onClick={handleOpenModal}>
                                Apply
                            </button>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="d-flex flex-row gap-4 align-items-center mb-3">
                            <div className="d-flex flex-row gap-2 align-items-center" ><LocationOn fontSize="small" /><span className="small lh-1 fst-italic">  {jobLocation}</span></div>
                            <div className="d-flex flex-row gap-2 align-items-center" ><CalendarTodayIcon fontSize="small" /><span className="small lh-1 fst-italic">  {formateDate(datePosted)}</span></div>
                            <div className="d-flex flex-row gap-2 align-items-center" ><SchoolIcon fontSize="small" /><span className="small lh-1 fst-italic">  {educationLevel}</span></div>
                            <div className="d-flex flex-row gap-2 align-items-center" ><WorkIcon fontSize="small" /><span className="small lh-1 fst-italic">  {status}</span></div>
                        </div>
                    </div>
                </div>
                <div className="blog-content">
                    {/* <h6>Job Responsibilities : </h6> */}
                    <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
                </div>
            </div>
            <GenericModal
                show={isModalVisible}
                title="Apply Job"
                onClose={handleCloseModal}
                onSave={handleSaveChanges}
            >
                <JsonForms
                    schema={schema}
                    uischema={uischema}
                    renderers={renderers}
                    cells={materialCells}
                    data={data}
                    validationMode={validationMode}
                    onChange={handleChange}
                />
            </GenericModal>
            <div class="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: 1200 }}>
                <div id="liveToast" className={toast ? 'toast show' : 'toast'} role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header">
                        <strong class="me-auto">Job Application</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setToast(false)}></button>
                    </div>
                    <div class="toast-body">
                        Job application saved successfully!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobContent
import getAxiosInstance from '../getAxiosInstance';

// API call for get entity data
const saveEntityData = async (apiPayload) => {

    const { entityDataUrl, data } = apiPayload;

    if (!data && Object.keys(data).length === 0) {
        return Promise.reject({
            error: true,
            message: 'Payload Data should not be null',
        });
    }

    if (!entityDataUrl) {
        return Promise.reject({
            error: true,
            message: 'Entity Data url should not be null',
        });
    }

    const instance = getAxiosInstance();

    return instance.post(`/entitydata/${entityDataUrl}`, data);
};

export default saveEntityData;

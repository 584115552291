export default function HeaderPages({title, subTitle}) {
    return (
        <section className="pb-0 bg-img1 video-background1" style={{marginTop: '5rem'}}>
            <div className="bg-overlay gradient-bg opacity-0"></div>
            {/* <video autoplay="" muted="" loop id="bg-video">
                <source src="video/home-bg-01.mp4" type="video/mp4"/>
                    Your browser does not support the video tag.
            </video> */}
            <div className="container">
                <div className="row">
                    <div className="col-md-12 position-relative">
                        <div className="cover-text mt-5">
                            <h5 className="text-white mb-2">{title ? title : '\u00A0'}</h5>
                            <p className="text-white">{subTitle ? subTitle : '\u00A0'}</p><br/>
                            {/* <div className="page_nav position-relative">
                                <span>You are here:</span> <a href="index.html">Home</a>
                                <span className="third-color">
                                    <i className="fa fa-angle-double-right"></i>About
                                </span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
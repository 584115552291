import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import { Contact } from '../../components/AppComponents/Contact';

const ContactPage = () => {
  const title = "Contact";
  const subTitle = "We'd Love to Hear From You.";
  return (
    <div>
        <NavbarPages/>
        <HeaderPages title={title} subTitle={subTitle}/>
        <Contact/>
        <ContactInfo/>
        <FooterMain/>
    </div>
  );
};

export default ContactPage;
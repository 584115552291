import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import { Careers } from '../../components/AppComponents/Careers';

const CareersPage = () => {
  const title = "Careers";
  const subTitle = "Embark on a Rewarding Career Journey.";
  return (
    <div>
        <NavbarPages/>
        <HeaderPages title={title} subTitle={subTitle}/>
        <Careers/>
        <ContactInfo/>
        <FooterMain/>
    </div>
  );
};

export default CareersPage;
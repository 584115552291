export default function ContactInfo() {
    return (
        <section id="contact" className="p-0">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-12 col-lg-6 order-md-2 order-lg-1 p-0">
                        <div className="address-box title mb-0 bg-img4">
                            {/* overlay */}
                            <div className="bg-overlay gradient-bg1 opacity-8"></div>
                            <div className="address-text text-center text-white position-relative wow " style={{ minHeight: '520px' }}>
                                <img src="/images/logo-black.png" className="mb-4" style={{ filter: 'brightness(0) invert(1)' }} width="200" alt="optimoz logo" /><br /><br />
                                <div className="row align-items-left">
                                    <div className="col-md-3 p-0">
                                        <img src="/images/cmmi-logo.png" className="mb-4" style={{ filter: 'brightness(0) invert(1)' }} width="80" alt="CMMI logo" /><br />
                                    </div>
                                    <div className="col-md-3 p-0">
                                        CMMI Development ML 3<br />
                                        CMMI Services ML 3<br />
                                    </div>
                                    <div className="col-md-3 p-0">
                                        <img src="/images/ISO-logo.png" className="mb-4" style={{ filter: 'brightness(0) invert(1)' }} width="100" alt="CMMI logo" /><br />
                                    </div>
                                    <div className="col-md-3 p-0">
                                        ISO 9001:2015<br />
                                        ISO 20000:2018<br />
                                    </div>
                                </div>
                                {/* Address */}
                                <p className="mb-3 text-white">
                                    Optimoz, Inc.
                                    2600 Tower Oaks Blvd. Suite 610
                                    Rockville, MD 20852<br />
                                    {/* Phone / Email */}
                                    +1.301.917.9116 | info@optimoz.com</p>
                                {/* Social Icon */}
                                <div className="address-social">
                                    <ul className="list-unstyled">
                                        <li><a className="facebook-text-hvr" href="https://www.facebook.com/optimozinc"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                        <li><a className="twitter-text-hvr" href="https://twitter.com/optimoz1"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a className="linkedin-text-hvr" href="https://www.linkedin.com/company/optimozinc"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>
                                <div style={{ marginTop: '50px' }}>
                                    <img src="/images/cmmi-logo.png" style={{ height: '75px', border: '1px solid #eeeeee', borderRadius: '5px', padding: '3px', margin: '3px', backgroundColor: '#fff', marginTop: '0px' }} />
                                    <img src="/images/ISO-logo.png" style={{ height: '75px', border: '1px solid #eeeeee', borderRadius: '5px', padding: '3px', margin: '3px', backgroundColor: '#fff', marginTop: '0px' }} />
                                    <img src="/images/uschamber-webstickers.png" style={{ height: '75px', border: '1px solid #eeeeee', borderRadius: '5px', padding: '3px', margin: '3px', backgroundColor: '#fff', marginTop: '0px' }} />
                                    <img src="/images/hl7-gold-logo.png" style={{ height: '75px', border: '1px solid #eeeeee', borderRadius: '5px', padding: '3px', margin: '3px', backgroundColor: '#fff', marginTop: '0px' }} />
                                    <img src="/images/AIRA-logo.png" style={{ height: '75px', border: '1px solid #eeeeee', borderRadius: '5px', padding: '3px', margin: '3px', marginTop: '0px' }} />
                                    <img src="/images/himss_cm_seal_gold.png" style={{ height: '75px', border: '1px solid #eeeeee', borderRadius: '5px', padding: '3px', margin: '3px', marginTop: '0px' }} />
                                    <img src="/images/optalk-icon.png" style={{ height: '75px', border: '1px solid #eeeeee', borderRadius: '5px', padding: '3px', margin: '3px', backgroundColor: '#fff', marginTop: '0px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 order-md-1 order-lg-2 p-0">
                        <iframe loading="lazy" src="https://maps.google.com/maps?q=Optimoz%2C%20Inc.&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" allowfullscreen="" width="100%" height="520" frameborder="0" style={{ minHeight: '520px', border: '0' }}></iframe>
                    </div>
                </div>
            </div>
        </section>
    );
}
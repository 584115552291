import React, { useState, useCallback } from 'react';
import { JsonForms } from '@jsonforms/react';
import { Toast, ToastContainer } from 'react-bootstrap';
import {
    materialRenderers,
    materialCells
} from '@jsonforms/material-renderers';
import RichTextEditor from '../../ui/RichTextEditor';
import { schema, uischema } from './schemas';

// Constant
import { DEFAULT_FORM_VALIDATION_MODE, FORM_VALIDATE_MODE } from '../../../constants/configuration';

// API 
import saveEntityData from '../../../apis/Entity/saveEntity';

export function JobPost() {
    const [data, setData] = useState({});
    const [errors, setErrors] = useState([]);
    const [toast, setToast] = useState(false);
    const [validationMode, setValidationMode] = useState(DEFAULT_FORM_VALIDATION_MODE);
    // Define an async function to submit the data
    const submitData = useCallback(async (event, payload) => {

        event.preventDefault();

        // set validation mode to validate on save
        setValidationMode(FORM_VALIDATE_MODE);

        // check if there are any errors
        if (errors && errors.length > 0) {
            return;
        }

        try {
            await saveEntityData({ entityDataUrl: 'jobs', data: payload });
            setToast(true);
            setData({});
            setErrors([]);
            setValidationMode(DEFAULT_FORM_VALIDATION_MODE);
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error(error)
        }
    }, [setToast, errors]);

    const richTextEditorRenderer = {
        tester: (uiSchema) => (uiSchema.options && uiSchema.options.richTextEditor ? 3 : -1),
        renderer:
            RichTextEditor
    };

    const renderers = [
        ...materialRenderers,
        richTextEditorRenderer,
    ];

    return (
        <section className="career-section">
            <div className="container">
                <h3 className="gradient-text1 mb-4">Post a new job</h3>
                <form onSubmit={(event) => submitData(event, data)}>
                    <JsonForms
                        schema={schema}
                        uischema={uischema}
                        renderers={renderers}
                        cells={materialCells}
                        validationMode={validationMode}
                        data={data}
                        onChange={({ errors, data }) => { setData(data); setErrors(errors); }}
                    />
                    <button type="submit"
                        className="btn btn-large btn-gradient btn-rounded mt-4">
                        Submit
                    </button>
                </form>
            </div>

            <div class="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: 1200 }}>
                <div id="liveToast" className={toast ? 'toast show' : 'toast'} role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header">
                        <strong class="me-auto">Job Post</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setToast(false)}></button>
                    </div>
                    <div class="toast-body">
                        Job saved successfully!
                    </div>
                </div>
            </div>
        </section>
    );
}
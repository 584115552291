import Keycloak from "keycloak-js";

const keycloakConfig ={
    url: 'https://sso.singlenum.com/auth',
    realm: 'singlenum',
    clientId: 'optimoz'
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;

export default function ProductsServices() {
    return (
        <section id="products-and-services" className="bg-light">
            <div className="container">
                {/* Heading */}
                <div className="row">
                    <div className="col-md-12 text-center wow ">
                        <div className="title d-inline-block">
                            <h6 className="mb-3">Optimoz AI Solution and Infrastructure Services</h6>
                            <h2 className="gradient-text1 mb-3">Products and Services</h2>
                            <p>Optimoz delivers the experience and expertise you need to leverage advances in AI, data management, software engineering, DevSecOps, and cloud technologies to improve your enterprise.  Our work spans the entire solution development lifecycle from strategy through technology planning, architecture, development, operations, and on-going enhancement.  </p>
                        </div>
                    </div>
                </div>

                {/* Products */}
                <div className="row">
                    <div id="price-slider" className="owl-carousel owl-item wow ">
                        {/* price item */}
                        <div className="price-item item text-start">
                            <h4 className="alt-font gradient-text1 d-inline-block font-weight-500 mb-4">optalk.ai</h4>
                            <div className="price-tag d-flex align-items-center">
                                <div className="price alt-font text-dark-gray">
                                    <h3 className="currency"><i className="fa fa-lock" aria-hidden="true"></i></h3>
                                </div>
                                <p className="price-text no-margin">High performance solutions built on our proprietary AI framework and industry-leading generative AI technologies.</p>
                            </div>
                            <ul className="packages">
                                <li><i className="fa fa-check" aria-hidden="true"></i>Full access</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Unlimited Bandwidth</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Powerful Admin Panel</li>
                                <li><i className="fa fa-times" aria-hidden="true"></i>Email Accounts</li>
                                <li><i className="fa fa-times" aria-hidden="true"></i>8 Free Forks Every Months</li>
                            </ul>
                            <a href="javascript:void(0);" className="btn btn-large btn-gradient btn-rounded w-100">Learn More</a>
                        </div>
                        {/* price item */}
                        <div className="price-item text-start">
                            <h4 className="alt-font d-inline-block font-weight-500 mb-4">DevSecOps</h4>
                            <div className="price-tag d-flex align-items-center">
                                <div className="price alt-font text-dark-gray">
                                    <h3 className="currency"><i className="fa fa-gears" aria-hidden="true"></i></h3>
                                </div>
                                <p className="price-text no-margin">Modern processes that speed the development pipeline, enhance operations, and increase security.</p>
                            </div>
                            <ul className="packages">
                                <li><i className="fa fa-check" aria-hidden="true"></i>Full access</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Unlimited Bandwidth</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Powerful Admin Panel</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Email Accounts</li>
                                <li><i className="fa fa-times" aria-hidden="true"></i>8 Free Forks Every Months</li>
                            </ul>
                            <a href="javascript:void(0);" className="btn btn-large btn-black btn-rounded w-100">Learn More</a>
                        </div>
                        {/* price item */}
                        <div className="price-item text-start">
                            <h4 className="alt-font gradient-text1 d-inline-block font-weight-500 mb-4">Cloud Engineering</h4>
                            <div className="price-tag d-flex align-items-center">
                                <div className="price alt-font text-dark-gray">
                                    <h3 className="currency"><i className="fa fa-cloud" aria-hidden="true"></i></h3>
                                </div>
                                <p className="price-text no-margin">Cloud management services to improve the performance of networks, infrastructure, and applications.</p>
                            </div>
                            <ul className="packages">
                                <li><i className="fa fa-check" aria-hidden="true"></i>Full access</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Unlimited Bandwidth</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Powerful Admin Panel</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>Email Accounts</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i>8 Free Forks Every Months</li>
                            </ul>
                            <a href="javascript:void(0);" className="btn btn-large btn-gradient btn-rounded w-100">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
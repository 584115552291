import React, { useState, useEffect, useCallback } from 'react';
import getEntityDataList from '../../../apis/Entity/getEntityList';
import { BLOG_DEFAULT_AUTHOR_NAME, BLOG_THUMBNAIL_BASE64 } from '../../../constants/configuration';

export function Blogs() {
    const [blogPosts,
        setBlogPosts] = useState([]);
    const [users, setUsers] = useState([]);
    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                const response = await getEntityDataList({
                    pageNumber: 1,
                    pageSize: 10,
                    sortKey: "datePosted",
                    sortOrder: -1,
                    apiUrl: "blogs",
                });
                setBlogPosts(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchBlogPosts();
    }, []);
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await getEntityDataList({
                    pageNumber: 1,
                    pageSize: 10,
                    apiUrl: "users",
                });
                setUsers(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchUsers();
    }, []);


    const userInformation = useCallback((userId) => {
        const user = users.find((user) => user._id === userId);
        if (user) {
            return user.firstName + " " + user.lastName;
        }
        return BLOG_DEFAULT_AUTHOR_NAME
    }, [users])

    const redirectToBlog = (blogId) => {
        window.location.href = `/blog/${blogId}`;
    };

    return (
        <section className="career-section wm-bg-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* <h2 className="gradient-text1 mb-3">Blogs</h2> */}
                        {blogPosts.length === 0 &&
                            <div class="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {blogPosts.map((blogPost) => (
                            <div className="job-posting d-flex" key={blogPost._id}
                                style={{ marginTop: "0px", borderBottom: "1px solid lightgray", marginBottom: "20px", gap: "3rem" }}>
                                <img height={200} width={200} className='rounded' src={blogPost?.blogPicture || BLOG_THUMBNAIL_BASE64} alt={blogPost?.blogTitle} />
                                <div className='d-flex w-100 flex-column' >
                                    <div className='d-flex justify-content-between' alignItems="center" key={blogPost._id}>
                                        <div className='col-9'>
                                            <h5>{blogPost.blogTitle}</h5>
                                            <p className="job-posting-date">
                                                {blogPost.datePosted}:&nbsp;
                                                {userInformation(blogPost.author)}
                                            </p>
                                        </div>
                                        <div className='col-3' style={{ textAlign: "right" }}>
                                            <button className="btn btn-small btn-gradient btn-rounded"
                                                onClick={() => redirectToBlog(blogPost._id)}>
                                                Read More
                                            </button>
                                        </div>
                                    </div>

                                    <div className="job-posting-description">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: `${blogPost.excerpt}`
                                            }} />
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { About } from '../../components/AppComponents/About';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import { FooterMain } from '../../components/AppComponents/FooterMain';

const AboutPage = () => {
  const title = "About Us";
  const subTitle = "Get to Know Us Better.";
  return (
    <div>
        <NavbarPages/>
        <HeaderPages title={title} subTitle={subTitle}/>
        <About/>
        <ContactInfo/>
        <FooterMain/>
        {/* <h1>You reached a protected route</h1> */}
    </div>
  );
};

export default AboutPage;
import { BLOG_THUMBNAIL_BASE64 } from '../../../constants/configuration';
import { ClientsPartnersMenu } from '../ClientsPartnersMenu';

export function Clients() {

    const clients = [
        {
            name: 'CDC: Immunization Information System',
            logo: '/images/site/customer/cdc-logo-300x300.png',
            short_description: 'By law, every US state and territory is required to maintain an immunization registry and submit immunization data to the CDC. Currently, states submit data via a CDC-managed application called the Immunization Gateway. CDC developed and released the Immunization Gateway almost 30 years ago. The application is increasingly unreliable and hard to maintain. CDC needed a new version of the Immunization Gateway – built using modern web and cloud technologies – to more easily ingest and use immunization data.',
            _id: 'cdc'
        },
        {
            name: 'MD Judiciary: Turnkey Automated Document Creation System',
            logo: '/images/site/customer/maryland-court.png',
            short_description: 'The Maryland Judiciary’s approach to managing tenant evictions was predominantly paper-based and highly inefficient – creating issues for landlords and employees of the court. The Maryland Judiciary needed an automated solution that streamlined the process of managing the document flow for tenant evictions.',
            _id: 'md-judiciary'
        },
        {
            name: 'Inxeption – a logistics management company',
            logo: '/images/site/customer/inxeption-300x300.png',
            short_description: 'Inxeption builds and operates a blockchain-driven technology platform for digitizing the global Industrial Commerce value chain. The Company had grown rapidly, and technical operations were becoming very complex and hard to manage. Inxeption needed to put in place world-class DevSecOps processes and a more scalable approach to managing its infrastructure to keep up with demand.',
            _id: 'inxeption'
        },
        {
            name: 'DHS/USCIS: Architecture and Engineering Services (AES)',
            logo: '/images/site/customer/dhs-uscis.png',
            short_description: 'We support the enterprise platforms, refactoring and AWS cloud migration of multiple applications, that manage the daily work of USCIS, in four areas: (1) Long-term IT security compliance through DevSecOps, (2) transformation of the USCIS application development methodology from Waterfall to Agile, (3) modernization of the software deployment process, and (4) introduction of leading edge Cloud infrastructure tools and management processes.',
            _id: 'dhs-uscis-aes'
        }
    ]

    const redirectToClient = (clientId) => {
        window.location.href = `/client/${clientId}`;
    };

    return (
        <section className="clients-section bg-light wm-bg-section">
            <div id="clients" className="container">
                <div className="row">
                    <div className="col-3" style={{padding: '0px', borderRight: '1px solid lightgray'}}>
                        <ClientsPartnersMenu />
                    </div>
                    <div className="col-9">
                        {clients.map((client) => (
                        <div className="job-posting d-flex"
                            style={{ marginTop: "0px", borderBottom: "1px solid lightgray", marginBottom: "20px", gap: "1rem" }}>
                            <img height={100} width={100} className='rounded' src={client.logo || BLOG_THUMBNAIL_BASE64} alt={client.name} />
                            <div className='d-flex w-100 flex-column' >
                                <div className='d-flex justify-content-between' alignItems="center">
                                    <div className='col-9'>
                                        <h5>{client.name}</h5>
                                    </div>
                                    <div className='col-3' style={{ textAlign: "right" }}>
                                        <button className="btn btn-small btn-gradient btn-rounded"
                                            onClick={() => redirectToClient(client._id)}>
                                            Read More
                                        </button>
                                    </div>
                                </div>

                                <div className="job-posting-description">
                                    <div>
                                        {client.short_description}
                                    </div>
                                </div>
                            </div>
                        </div>
                         ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
import NavbarPages from '../../../components/AppComponents/NavbarPages';
import HeaderPages from '../../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../../components/AppComponents/FooterMain';
import ContactInfo from '../../../components/AppComponents/ContactInfo';
import UsersList from '../../../components/admin/Users';
const UsersPage = () => {

    return (
        <div>
            <NavbarPages />
            <HeaderPages title={"Users"} subTitle={"Users List"} />
            <UsersList />
            <ContactInfo />
            <FooterMain />
        </div>
    );
}

export default UsersPage
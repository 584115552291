export function About() {
    return (
        <section id="products-and-services" className="bg-light wm-bg-section">
            <div className="container">
                {/* Heading */}
                <div className="row">
                    <div className="col-md-12 text-center wow ">
                        <div className="title d-inline-block">
                            <h6 className="mb-3">Transforming the public health IT</h6>
                            <h2 className="gradient-text1">Trusted Ally in Advancing Public Health</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-left wow ">
                        <p>When it’s time to use technology to transform your enterprise, it’s time to call on Optimoz.  Our clients present us with some of the most difficult technical challenges imaginable. We are experts in using emerging technologies to reshape your enterprise. Our capabilities include: </p>
                        <p className="mb-1"> Our capabilities include:</p>
                        <ul className="packages">
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Artificial Intelligence </li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Data Management </li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>DevSecOps </li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Cloud Engineering & Optimization </li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Low/No-Code Development </li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Software Engineering </li>
                        </ul>

                        <p>Plus, we provide the leadership, experience, processes, and focus on enterprise value necessary to ensure that your enterprise can use your new capabilities to maximum effect.</p>

                        <p className="mt-4"><strong>The key benefits for your enterprise include:   </strong></p>

                        <ul className="packages">
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Breakthrough insights to improve decision-making.</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Better productivity and business agility. </li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Faster, more predictable solution development. </li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Increased systems and application performance and reliability</li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Integrated security that protects at the infrastructure, platform, and application layers. </li>
                            <li><i className="fa fa-circle-o" aria-hidden="true"></i>Reduced operating costs and enterprise risk</li>
                        </ul>

                        <p className="mb-3"><strong className="gradient-text1">Our Approach</strong> Is based on proven best practices in artificial intelligence, data management, software engineering, DevSecOps, cloud engineering, and technology management.  </p>
                        <p className="mb-3"><strong className="gradient-text1">Our Team</strong> is a mixture of CTOs, architects, data scientists, senior- and mid-level full-stack software developers, DevSecOps experts, business analysts, product managers, and engagement managers, who bring the right expertise and experience to every project. </p>
                        <p className="mb-3"><strong className="gradient-text1">Our Definition of Success</strong> is simple.  We aim to make your enterprise more productive by (1) delivering high performance, cost effective, secure solutions that add business value, and (2) transferring knowledge to your team to enable your enterprise to easily manage your solutions and make the right technical decisions going forward.   </p>

                    </div>
                </div>

            </div>
        </section>
    );
}
// Function to handle cookies
const Cookie = {
    createCookie: (name, value, hours) => {
        if (!hours) {
            hours = 8760;
        }

        var date = new Date();
        date.setTime(date.getTime() + hours * 60 * 60 * 1000);
        var expires = '; expires=' + date.toGMTString();
        document.cookie = name + '=' + value + expires + '; path=/';
    },
    readCookie: (name) => {
        var match = document?.cookie?.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) {
            return match[2];
        }
    },
    removeCookie: (cookieName) => {
        document.cookie = cookieName + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

export default Cookie;

import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import { Capabilities } from '../../components/AppComponents/Capabilities';

const CapabilitiesPage = () => {
  const title = "Capabilities Briefing";
  const subTitle = "Discover Our Skills and Solutions.";
  return (
    <div>
        <NavbarPages/>
        <HeaderPages title={title} subTitle={subTitle}/>
        <Capabilities/>
        <ContactInfo/>
        <FooterMain/>
    </div>
  );
};

export default CapabilitiesPage;
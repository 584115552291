import React, { useEffect } from 'react';

import { Outlet } from 'react-router-dom'
import Cookie from './Cookie';
import validateToken from './TokenValidator';


const PrivateRoutes = () => {
  //  read cookie
  const [loading, setLoading] = React.useState(true);
  const [authenticated, setAuthenticated] = React.useState(false);

  useEffect(() => {
    try {
      if(validateToken()) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (<div>Loading...</div>)
  }

  if (!authenticated) {
    //  redirect to login page
    Cookie.createCookie('redirectUri', window.location.pathname, 1);
    window.location.href = '/login';
  } else {
    return <Outlet/>
  }
} 

export default PrivateRoutes;
export const schema = {
    "title": "Job Form",
    "type": "object",
    "required": [
        "jobTitle"
    ],
    "definitions": {
        "eduGroup": {
            "enum": [
                "Masters",
                "Bachelors",
                "Highschool",
                "Associate",
                "PHD"
            ]
        }
    },
    "properties": {
        "jobResponsibilities": {
            "title": "Job Responsibilities",
            "type": "string",
            "default": "<h1>test</h1>"
        },
        "jobLocation": {
            "type": "string",
            "title": "Job Location",
            "enum": [
                "Rockville, MD",
                "Ahmedabad, GJ",
                "Remote"
            ]
        },
        "datePosted": {
            "type": "string",
            "format": "date",
            "title": "Date"
        },
        "jobTitle": {
            "type": "string",
            "title": "Job Title"
        },
        "status": {
            "type": "string",
            "title": "Status",
            "enum": [
                "Active",
                "Closed",
                "Draft",
                "Inactive"
            ]
        },
        "educationLevel": {
            "title": "Education Required",
            "$ref": "#/definitions/eduGroup"
        }
    }
}
export const uischema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "Control",
            "scope": "#/properties/jobTitle",
            "label": "New Job Title",
            "options": {
                "placeholder": "Enter the New job title",
                "focus": true
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/jobResponsibilities",
            "label": "Job Responsibilities",
            "options": {
                "multi": true,
                "rows": 5,
                "default": 3,
                "richTextEditor": true,
                "placeholder": "Enter Job Responsibilities"
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/jobLocation",
            "label": "Job Location",
            "options": {
                "placeholder": "Select job location",
                "format": "select"
            }
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/datePosted",
                    "label": "Date",
                    "options": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/status",
                    "label": "Status",
                    "options": {
                        "format": "dropdown"
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/educationLevel",
                    "label": "Education Required",
                    "options": {
                        "format": "select"
                    }
                }
            ]
        },

    ]
}
export function VideoMain() {
    const videos = [
        'video/home-bg-01.mp4',
    ];
    const thumbs = [
        'video/home-bg-01.png',
    ];
    const index = 0; //Math.floor(Math.random() * videos.length);
    const videomp4 = videos[index];
    const thumb = thumbs[index];
    return (
        <section id="home" className="p-0 single-slide dark-slider">
            <h2 className="d-none">hidden</h2>
            <div id="rev_single_wrapper" className="rev_slider_wrapper fullscreen-container" data-alias="wexim_slider_01" data-source="gallery" style={{ background: 'transparent', padding: '0px' }}>
                {/* START REVOLUTION SLIDER 5.4.8.1 fullscreen mode */}
                <div id="rev_single" className="rev_slider fullscreenbanner" style={{ display: 'none' }} data-version="5.4.8.1">
                    <ul>
                        {/* SLIDE */}
                        <li data-index="rs-3" data-transition="crossfade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default" data-thumb="images/slide-img2.jpg" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">

                            {/* MAIN IMAGE */}
                            <img src={thumb} alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" className="rev-slidebg" data-no-retina />
                            {/* LAYERS */}

                            {/* Overlay */}
                            <div className="bg-black bg-overlay opacity-4 z-index-1"></div>

                            <div className="rs-background-video-layer"
                                data-forcerewind="on"
                                data-volume="mute"
                                data-videowidth="100%"
                                data-videoheight="100vh"
                                data-videomp4={videomp4}
                                data-videopreload="auto"
                                data-videoloop="loopandnoslidestop"
                                data-forceCover="1"
                                data-aspectratio="16:9"
                                data-autoplay="true"
                                data-autoplayonlyfirsttime="false"></div>

                            <div className="tp-caption tp-resizeme"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['-120','-80','-80','-80']"
                                data-whitespace="nowrap" data-responsive_offset="on"
                                data-width="['none','none','none','none']" data-type="text"
                                data-textalign="['center','center','center','center']"
                                data-transform_idle="o:1;"
                                data-transform_in="x:-50px;opacity:0;s:2000;e:Power3.easeOut;"
                                data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                data-start="1000" data-splitin="none" data-splitout="none">
                                <h2 className="text-white">aixmoz</h2>
                            </div>

                            <div className="tp-caption tp-resizeme"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['-30','0','0','0']"
                                data-whitespace="nowrap" data-responsive_offset="on"
                                data-width="['none','none','none','none']" data-type="text"
                                data-textalign="['center','center','center','center']"
                                data-transform_idle="o:1;"
                                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                data-transform_out="s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                data-start="1000" data-splitin="none" data-splitout="none">
                                <h2 className="font-weight-600 text-white">Secured AI in a Box</h2>
                            </div>
                            <div className="tp-caption tp-resizeme"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['50','75','75','75']"
                                data-whitespace="nowrap" data-responsive_offset="on"
                                data-width="['none','none','none','none']" data-type="text"
                                data-textalign="['center','center','center','center']" data-fontsize="['24','24','20','20']"
                                data-transform_idle="o:1;"
                                data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:2;sY:2;skX:0;skY:0;opacity:0;s:1000;e:Power2.easeOut;"
                                data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                                data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                                data-start="1500" data-splitin="none" data-splitout="none">
                                <p className="text-white">Making AI works for security & privacy conscious enterprises!</p>
                            </div>
                            <div className="tp-caption tp-resizeme"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['140','160','160','160']"
                                data-width="['500','500','500','500']" data-textalign="['center','center','center','center']" data-type="text"
                                data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:1500;e:Power4.easeInOut;" data-transform_out="s:900;e:Power2.easeInOut;s:900;e:Power2.easeInOut;" data-start="2000" data-splitin="none" data-splitout="none" data-responsive_offset="on">
                                <a className="btn btn-blue btn-large btn-rounded" href="#aixmoz">Learn More</a>
                            </div>
                        </li>
                    </ul>
                </div>
                {/* END REVOLUTION SLIDER */}
            </div>
        </section>
    );
}
import getAxiosInstance from '../getAxiosInstance';

const getEntitiesDataListByFilter = ({ pageNumber = 1, pageSize = 10, sortKey = null, sortOrder = -1, apiUrl, filter }) => {
    const instance = getAxiosInstance();

    if (!apiUrl) {
        return Promise.reject({
            error: true,
            message: 'Api url should not be null or undefined',
        });
    }

    if (!filter && Object.keys(filter).length === 0) {
        return Promise.reject({
            error: true,
            message: 'Filter should not be null or undefined',
        });
    }

    const params = {
        page_number: pageNumber,
        page_size: pageSize,
        sort_key: sortKey,
        sort_order: sortOrder
    };

    return instance.post(
        `/entitydata/${apiUrl}/filter`,
        filter,
        { params }
    );
};

export default getEntitiesDataListByFilter;
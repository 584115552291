import getAxiosInstance from '../getAxiosInstance';

// API call for get entity data
const getEntityData = async (entityDataUrl) => {

    if(!entityDataUrl) {
        return Promise.reject({
            error: true,
            message: 'Entity data url must not be null or undefined',
        });
    }

    const instance = getAxiosInstance();

    return instance.get(`/entitydata/${entityDataUrl}`);
};

export default getEntityData;

import SideNavMenu from "../SideNavMenu";
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useIsMobile } from "../../../helpers/DeviceDetect";

const CustomToggle = React.forwardRef(({ children, onClick, isOpen }, ref) => (
    <a
        className="nav-link scroll1"
        href="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
    >
        {children}
        {isOpen ? (
            <ArrowDropUpIcon style={{ marginLeft: 8 }} />
        ) : (
            <ArrowDropDownIcon style={{ marginLeft: 8 }} />
        )}
    </a>
));

export default function NavbarMain({ variant }) {
    const [open, setOpen] = useState(false);
    const isMobile = useIsMobile();

    return (
        <header>
            <nav className={`navbar navbar-top-default ${variant} navbar-expand-lg navbar-simple nav-box-round`}>
                <div className="container">
                    <a href="/" title="Logo" className="logo scroll1">
                        <img src="/images/logo-black.png" className="logo-dark" alt="logo" />
                        <img src="/images/logo-white.png" alt="logo" className="logo-light default" />
                    </a>
                    <div className="collapse navbar-collapse" id="optimoz">
                        <div className="navbar-nav w-100 alt-font">
                            {/* Dropdown Menu - About*/}
                            <Dropdown
                                onToggle={() => setOpen(prev => !prev)}
                                className="nav-item bg-none">
                                <Dropdown.Toggle as={CustomToggle} isOpen={open} className="nav-link scroll1" id="dropdown-basic">
                                    About
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-end">
                                    <Dropdown.Item href="/about">About us</Dropdown.Item>
                                    <Dropdown.Item href="/blogs">Insights</Dropdown.Item>
                                    <Dropdown.Item href="/contact">Contact</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* Dropdown Menu - Solutions*/}
                            <Dropdown
                                onToggle={() => setOpen(prev => !prev)}
                                className="nav-item bg-none">
                                <Dropdown.Toggle as={CustomToggle} isOpen={open} className="nav-link scroll1" id="dropdown-basic">
                                    Solutions
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-end">
                                    <Dropdown.Item href="/optalk">optalk.ai</Dropdown.Item>
                                    <Dropdown.Item href="/healthit">Healthcare IT</Dropdown.Item>
                                    <Dropdown.Item href="/cloud-engineering">Cloud Engineering</Dropdown.Item>
                                    <Dropdown.Item href="/devsecops">DevSecOps</Dropdown.Item>
                                    <Dropdown.Item href="/software-engineering">Software Engineering</Dropdown.Item>
                                    <Dropdown.Item href="/data-management">Data Management</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <a className="nav-link scroll1" href="/capabilities">Capabilities</a>
                            <a className="nav-link scroll1" href="/clients-and-partners">Clients & Partners</a>
                            <a className="nav-link scroll1" href="/careers">Careers</a>
                            <a className="nav-link scroll1" href="https://optalk.ai" target="_blank">optalk.ai</a>
                            <a className="nav-link scroll1" href="https://openhit.com" target="_blank">OpenHIT</a>
                            <span className="menu-line"><i className="fa fa-angle-down" aria-hidden="true"></i></span>
                        </div>
                    </div>

                    {<a href="javascript:void(0)" className="d-inline-block sidemenu_btn" id="sidemenu_toggle" style={isMobile ? { visibility: "unset" } : { visibility: "hidden" }}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>}
                </div>
            </nav>

            <SideNavMenu />
        </header>
    );
}

export const schema = {
    "title": "Blog Post",
    "type": "object",
    "required": [
        "blogTitle",
        "blog",
        "excerpt",
        "datePosted",
        "blogType",
        "author",
        "status"
    ],
    "properties": {
        "blogTitle": {
            "title": "Blog Title",
            "type": "string",
            "default": ""
        },
        "blog": {
            "title": "Blog",
            "type": "string",
            "default": ""
        },
        "excerpt": {
            "type": "string",
            "title": "Excerpt"
        },
        "blogPicture": {
            "type": "string",
            "format": "data-url",
            "title": "Upload Picture"
        },
        "datePosted": {
            "type": "string",
            "format": "date",
            "title": "Date"
        },
        "blogType": {
            "type": "string",
            "title": "Blog Type",
            "enum": [
                "News",
                "Insight",
                "Blog",
            ]
        },
        "author": {
            "type": "string",
            "title": "Author",
            "enum": [
                "Patel"
            ]
        },
        "status": {
            "type": "string",
            "title": "Status",
            "enum": [
                "Active",
                "Draft",
                "Inactive"
            ]
        }
    }
}
export const uischema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "Control",
            "scope": "#/properties/blogTitle",
            "label": "Blog Title",
            "options": {
                "placeholder": "Enter the blog title",
                "focus": true
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/blog",
            "label": "Blog",
            "placeholder": "Enter your content here...",
            "options": {
                "multi": true,
                "rows": 5,
                "default": 5,
                "richTextEditor": true,
                "placeholder": "Enter blog content"
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/excerpt",
            "label": "Excerpt",
            "options": {
                "multi": true,
                "rows": 3,
                "default": 3,
                "richTextEditor": true,
                "placeholder": "Enter excerpt"
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/blogPicture",
            "label": "Upload Blog Picture",
            "options": {
                "fileRenderer": "file",
                "accept": ".png .jpg .jpeg",
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/blogType",
            "label": "Blog Type",
            "options": {
                "format": "dropdown"
            }
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/datePosted",
                    "label": "Date",
                    "options": {
                        "format": "YYYY-MM-DD"
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/author",
                    "label": "Author",
                    "options": {
                        "format": "dropdown"
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/status",
                    "label": "Status",
                    "options": {
                        "format": "dropdown"
                    }
                }
            ]
        },

    ]
}
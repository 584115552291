import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import JobContent from "../../components/AppComponents/JobContent";

// API 
import getEntityData from "../../apis/Entity/getEntity";
const JobContentPage = () => {

    // Get the blog ID from the URL parameters
    const { jobId } = useParams();
    // State to store the fetched data
    const [job, setJob] = useState(null);

    // useEffect to fetch data on component mount
    useEffect(() => {
        // Define an async function to fetch the data
        const fetchData = async () => {
            try {
                // Fetch data from the API
                const response = await getEntityData(`jobs/${jobId}`);
                // Update the state with the fetched data
                setJob(response.data);
            } catch (error) {
                // Handle any errors that occurred during the fetch
                console.error(error)
            }
        };
        // Call the fetchData function
        fetchData();
    }, []); // Empty dependency array ensures this runs only once on mount


    return (
        <div>
            <div>
                <NavbarPages />
                <HeaderPages />
                <JobContent
                    jobId={jobId}
                    datePosted={job?.datePosted || ""}
                    educationLevel={job?.educationLevel || "Education Level"}
                    jobLocation={job?.jobLocation || "Job Location"}
                    jobResponsibilities={job?.jobResponsibilities || "Job Responsibilities"}
                    jobTitle={job?.jobTitle || "Job Title"}
                    status={job?.status || "Status"}
                />
                <ContactInfo />
                <FooterMain />
            </div>
        </div>
    );
};
export default JobContentPage
import React from 'react';
import NavbarPages from '../../../components/AppComponents/NavbarPages';
import HeaderPages from '../../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../../components/AppComponents/FooterMain';
import ContactInfo from '../../../components/AppComponents/ContactInfo';
import { JobPost } from '../../../components/admin/JobPost';

const JobPostPage = () => {
  const title = "Job Post";
  const subTitle = "Embark on a Rewarding Career Journey.";

  return (
    <div width="50%">
      <NavbarPages />
      <HeaderPages title={title} subTitle={subTitle} />
      <JobPost />
      <ContactInfo />
      <FooterMain />
    </div>
  );
};

export default JobPostPage;
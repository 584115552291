import React from "react";
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import { CASE_STUDIES } from "../../constants/case-study";
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { Clients } from "../../components/AppComponents/Clients";
const CaseStudyPage = () => {
    const title = "Clients & Partners";
    const subTitle = "Optimoz - Case Studies & Contract Vehicles";
    return (
        <div>
            <NavbarPages />
            <HeaderPages title={title} subTitle={subTitle} />
            <Clients />
            <ContactInfo />
            <FooterMain />
        </div >
    );
};
export default CaseStudyPage
import React, { useState, useEffect } from 'react';
import getEntityDataList from '../../../apis/Entity/getEntityList';

export function Careers() {
    const [jobPostings,
        setJobPostings] = useState([]);
    useEffect(() => {
        const fetchJobPostings = async () => {
            try {
                const response = await getEntityDataList({
                    pageNumber: 1,
                    pageSize: 10,
                    sortKey: "datePosted",
                    sortOrder: -1,
                    apiUrl: "jobs",
                });
                // setJobPostings(response.data.filter(jobPost => jobPost.status === "Active"));
                setJobPostings(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchJobPostings();
    }, []);

    const redirectToApply = (jobId) => {
        window.location.href = `/job/view/${jobId}`;
    };

    return (
        <section className="career-section wm-bg-section">
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <h2 className="red-header gradient-text1 mb-3">Dream High - Achieve More</h2>
                        <p>Optimoz is an elite technology consulting firm based in Rockville, Maryland.   Our clients include federal and state agencies and mid-to-large sized corporations.  We deliver innovative technical solutions that enable our clients to realize tangible enterprise value.  If you have a solid grounding in software engineering, DevSecOps, cloud engineering, data management, or AI and are looking to leverage those skills to the fullest, Optimoz is the right company for you.
                        </p>
                        <br /><br />
                        <h3 className="gradient-text1 mb-3">Generous Benefits </h3>
                        <p>We offer competitive salary and benefits. Our benefits package includes:</p>
                        <ul className='px-3 pb-3'>
                            <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Health Insurance and Prescription Plan  </li>
                            <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Paid Time-Off (PTO)</li>
                            <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Holidays</li>
                            <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Training assistance for personal and professional growth.</li>
                        </ul>
                        <p>Optimoz is an Equal Employment Opportunity Employer. Qualified applicants will receive consideration for employment regardless of race, color, religion, sex, age, national origin, marital status, disability, veteran status, sexual orientation, genetic information, or any other protected status.</p>
                    </div>
                    <div className="col-6">
                        <h2 className="gradient-text1 mb-3">Current Openings</h2>
                        {jobPostings.length === 0 &&
                            <div class="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {jobPostings.map((jobPost) => (
                            <div div className="job-posting" key={jobPost._id}
                                style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}>
                                <div className='d-flex' alignItems="center" key={jobPost._id}>
                                    <div className='col-9'>
                                        <h5>{jobPost.jobTitle}</h5>
                                        <p className="job-posting-date">
                                            {jobPost.datePosted}:&nbsp;
                                            {jobPost.jobLocation}
                                        </p>
                                    </div>
                                    <div className='col-3' style={{ textAlign: "right" }}>
                                        <button className="btn btn-small btn-gradient btn-rounded"
                                            onClick={() => redirectToApply(jobPost._id)}>
                                            View&nbsp;Details
                                        </button>
                                    </div>
                                </div>

                                <div className="job-posting-description">
                                    {jobPost.jobResponsibilities.length <= 400
                                        ? (<div
                                            dangerouslySetInnerHTML={{
                                                __html: `${jobPost.jobResponsibilities}`
                                            }} />)
                                        : (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: `${jobPost
                                                        .jobResponsibilities
                                                        .slice(0, 400)}...  &nbsp;<a href="/apply/${jobPost._id}">Read more</a>`
                                                }}></div>
                                        )
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section >
    );
}
import { BLOG_THUMBNAIL_BASE64 } from '../../../constants/configuration';
import { ClientsPartnersMenu } from '../ClientsPartnersMenu';

export function Vehicles() {

    const vehicles = [
        {
            name: 'GSA: Multiple Award Schedule (MAS)',
            logo: '/images/site/customer/GSA-MAS.png',
            short_description: 'The GSA Multiple Award Schedule (MAS) for Professional Services and Cloud SIN (Special Item Number) offers a streamlined procurement process for federal agencies seeking a wide range of professional services and cloud computing solutions. This schedule provides access to pre-vetted vendors, ensuring compliance with federal regulations and offering competitive pricing. The Professional Services category includes consulting, engineering, financial, and training services, while the Cloud SIN covers various cloud-based services such as Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS). By leveraging the GSA MAS, agencies can efficiently procure essential services and technology to support their missions.',
            _id: 'gsa-mas-cloud-sin'
        },
        {
            name: 'Maryland Minority Business Enterprise (MBE)',
            logo: '/images/site/customer/mbe-logo.png',
            short_description: 'A Maryland Minority Owned Business (MBE) is a business that is at least 51% owned and controlled by one or more minority persons. A minority is generally defined as an individual who is African American, Hispanic American, Asian American, Native American, a Woman or a Disabled person. Additionally, the State also recognizes non-profit firms that are organized to promote the interests of the physically or mentally disabled.',
            _id: 'md-mbe'
        },
        {
            name: 'Maryland Cats+ Master Contract',
            logo: '/images/site/customer/MD-cats-plus.png',
            short_description: 'The Maryland Cats+ Master Contract is a procurement vehicle used by the state of Maryland to streamline the process of acquiring IT consulting and technical services. It allows state agencies to quickly and efficiently obtain the services they need from pre-approved vendors. The contract covers a wide range of IT services, including software development, network support, cybersecurity, and more. By using this master contract, the state can ensure consistent quality and fair pricing from vendors who have already been vetted and approved.',
            _id: 'md-cats-plus'
        }
    ]

    return (
        <section className="clients-section bg-light wm-bg-section">
            <div id="clients" className="container">
                <div className="row">
                    <div className="col-3" style={{padding: '0px', borderRight: '1px solid lightgray'}}>
                        <ClientsPartnersMenu />
                    </div>
                    <div className="col-9">
                        {vehicles.map((vehicle) => (
                        <div className="job-posting d-flex"
                            style={{ marginTop: "0px", borderBottom: "1px solid lightgray", marginBottom: "20px", gap: "1rem" }}>
                            <img height={100} width={100} className='rounded' src={vehicle.logo || BLOG_THUMBNAIL_BASE64} alt={vehicle.name} />
                            <div className='d-flex w-100 flex-column' >
                                <div className='d-flex justify-content-between' alignItems="center">
                                    <div className='col-9'>
                                        <h5>{vehicle.name}</h5>
                                    </div>
                                    <div className='col-3' style={{ textAlign: "right" }}>
                                    </div>
                                </div>

                                <div className="job-posting-description">
                                    <div>
                                        {vehicle.short_description}
                                    </div>
                                </div>
                            </div>
                        </div>
                         ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
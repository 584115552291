import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';

const FileRenderer = (props) => {
    const { data, handleChange, path } = props;

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                handleChange(path, reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <div className='form-group d-flex flex-column gap-2 pt-2'>
                <label className='h6 '>{props.uischema?.label || "Upload File"}</label>
                <input
                    type="file"
                    accept={props.uischema.options?.accept || '*/*'}
                    onChange={handleFileChange}
                />
            </div>
        </div>
    );
};

export default withJsonFormsControlProps(FileRenderer);

import React from 'react';

const GenericModal = ({ show, title, onClose, onSave, children, saveButtonText = "Apply" }) => {
    // If 'show' is false, don't render anything
    if (!show) return null;

    return (
        <>
            <div class="modal-backdrop-blur"></div>
            <div className="modal fade show d-block" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <button
                                type="button"
                                className="close"
                                onClick={onClose}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">{children}</div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary btn-black btn-rounded" onClick={onClose}>
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary btn-gradient btn-rounded" onClick={onSave}>
                                {saveButtonText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default GenericModal;

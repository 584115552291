import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import DoneLoginPage from './pages/DoneLoginPage';
import BlogsPage from './pages/BlogsPage';
import ContactPage from './pages/ContactPage';
import CapabilitiesPage from './pages/CapabilitiesPage';
import CareersPage from './pages/CareersPage';
import ServicesPage from './pages/ServicesPage';
import PrivateRoutes from './helpers/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import JobPostPage from './pages/admin/JobPostPage';
import BlogPostPage from './pages/admin/BlogPostPage';
import BlogContentPage from './pages/BlogContentPage';
import JobContentPage from './pages/JobContentPage';
import ApplicantsPage from './pages/admin/ApplicantsPage';
import HealthitPage from './pages/HealthitPage';
import DevSecOpsPage from './pages/DevSecOpsPage';
import DataManagementPage from './pages/DataManagmentPage';
import CloudEngineering from './pages/CloudEngineering';
import SoftwareEngineering from './pages/SoftwareEngineering';
import UsersPage from './pages/admin/UsersPage';
import CaseStudyPage from './pages/CaseStudyPage';
import VehiclesPage from './pages/VehiclesPage';
import OptalkPage from './pages/OptalkPage';
import ClientsContentPage from './pages/ClientsContentPage';


const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/capabilities" element={<CapabilitiesPage />} />
        <Route path="/healthit" element={<HealthitPage />} />
        <Route path="/devsecops" element={<DevSecOpsPage />} />
        <Route path="/data-management" element={<DataManagementPage />} />
        <Route path="/cloud-engineering" element={<CloudEngineering />} />
        <Route path="/software-engineering" element={<SoftwareEngineering />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/optalk" element={<OptalkPage />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/clients-and-partners" element={<CaseStudyPage />} />
        <Route path="/contracting-vehicles" element={<VehiclesPage />} />
        <Route path="/blog/:blogId" element={<BlogContentPage />} />
        <Route path="/job/view/:jobId" element={<JobContentPage />} />
        <Route path="/client/:clientId" element={<ClientsContentPage />} />

        <Route path="/security/doneLogin" element={<DoneLoginPage />} />


        <Route element={<PrivateRoutes />}>
          {/* <Route path="/services1" element={<ServicesPage />} />
          <Route path='/about1' element={<AboutPage />} /> */}
          <Route path="/admin/jobPost" element={<JobPostPage />} />
          <Route path="/admin/blogPost" element={<BlogPostPage />} />
          <Route path="/admin/job/applicants" element={<ApplicantsPage />} />
          <Route path="/admin/users" element={<UsersPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
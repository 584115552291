import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { Blogs } from '../../components/AppComponents/Blogs';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';

const BlogsPage = () => {
  const title = "Blogs";
  const subTitle = "Insights, Stories, and Updates.";
  return (
    <div>
        <NavbarPages/>
        <HeaderPages title={title} subTitle={subTitle}/>
        <Blogs/>
        <ContactInfo/>
        <FooterMain/>
    </div>
  );
};

export default BlogsPage;
import React from 'react';
import { LoaderMain } from '../../components/AppComponents/LoaderMain';
import NavbarMain from '../../components/AppComponents/NavbarMain';
import { VideoMain } from '../../components/AppComponents/VideoMain';
import { About } from '../../components/AppComponents/About';
import { FooterMain } from '../../components/AppComponents/FooterMain';
import ContactInfo from '../../components/AppComponents/ContactInfo';
import TeamMain from '../../components/AppComponents/TeamMain';
import Aixmoz from '../../components/AppComponents/Aixmoz';
import BlogMain from '../../components/AppComponents/BlogMain';
import Ebook from '../../components/AppComponents/Ebook';
import Portfolio from "../../components/AppComponents/Portfolio";
import ProductsServices from '../../components/AppComponents/ProductsServices';

const HomePage = () => {
  return (
    <div>
      <LoaderMain />
      <NavbarMain />
      <VideoMain />
      <section id="about" className='bg-light wm-bg-section'>
        <div className="container">
          {/* Heading */}
          <div className="row">
            <div className="col-md-12 text-center wow ">
              <div className="title d-inline-block">
                <h6 className="mb-3">AI-Driven Solution Development </h6>
                <h2 className="gradient-text1 mb-3">What we do</h2>
                {/* Features */}
                <div className="row mt-lg-5">
                  <div className="col-md-4 wow ">
                    <div className="feature-box">
                      <span className="item-number gradient-text1">
                        optalk.ai
                      </span>
                      <p>High performance solutions built using our proprietary AI framework and industry-leading generative AI technologies. </p>
                    </div>
                  </div>
                  <div className="col-md-4 wow ">
                    <div className="feature-box">
                      <span className="item-number gradient-text1">
                        Data Management
                      </span>
                      <p>Data science and big data management solutions that maximize insights from data. </p>
                    </div>
                  </div>
                  <div className="col-md-4 wow ">
                    <div className="feature-box">
                      <span className="item-number gradient-text1">
                        Software Engineering
                      </span>
                      <p>High performance custom software applications that increase enterprise value. </p>
                    </div>
                  </div>
                </div>
                <div className="row mt-lg-5">
                  <div className="col-md-4 wow ">
                    <div className="feature-box">
                      <span className="item-number gradient-text1">
                        DevSecOps
                      </span>
                      <p>Modern processes that speed the development pipeline, enhance operations, and increase security. </p>
                    </div>
                  </div>
                  <div className="col-md-4 wow ">
                    <div className="feature-box">
                      <span className="item-number gradient-text1">
                        Cloud Engineering
                      </span>
                      <p>Cloud management services to improve the performance of networks, infrastructure, and applications. </p>
                    </div>
                  </div>
                  <div className="col-md-4 wow ">
                    <div className="feature-box">
                      <span className="item-number gradient-text1">
                        Healthcare IT
                      </span>
                      <p>Data management and inter-operability solutions that make healthcare systems more efficient. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Aixmoz />
      <BlogMain />
      {/* <ProductsServices /> */}
      {/* <TeamMain /> */}
      <Portfolio />
      <Ebook />
      <ContactInfo />
      <FooterMain />
    </div>
  );
};

export default HomePage;
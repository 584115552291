import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Authenticator from '../../helpers/Authenticator';

const LogoutPage = () => {
  const {keycloak, initialized} = useKeycloak(); 

  useEffect(() => {
    if (!initialized) {
      return;
    }
    if (!keycloak.authenticated) {
      return;
    }
    Authenticator.logout(keycloak);
    // window.location.href = '/';
  }, [initialized, keycloak]);


  return (
    <div>
        Logging out...
    </div>
  );
};

export default LogoutPage;
import React from 'react';
import NavbarPages from '../../components/AppComponents/NavbarPages';
import HeaderPages from '../../components/AppComponents/HeaderPages';
import { FooterMain } from '../../components/AppComponents/FooterMain';

const SoftwareEngineering = () => {
    const title = "Software Engineering";
    const subTitle = "High-Performance Apps for Value";
    return (
        <div>
            <NavbarPages />
            <HeaderPages title={title} subTitle={subTitle} />
            <section id="dev-sec-ops" className="bg-light wm-bg-section">
                <div className="container">
                    {/* Heading */}
                    <div className="row">
                        <div className="col-md-12 text-center wow ">
                            <div className="title d-inline-block">
                                <h6 className="mb-3">Boost Enterprise Value</h6>
                                <h2 className="gradient-text1">Custom Software for Enterprise Growth</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-left wow ">
                            <p>Optimoz works with enterprises to build, maintain, and upgrade their most important applications.</p>
                            <p className="my-2">We bring best-in-class capabilities in following areas:</p>
                            <p className="mb-3"><strong className="gradient-text1">Building applications that scale effortlessly and economically</strong> as user demand and volumes of data increase.</p>
                            <p className="mb-3"><strong className="gradient-text1">Injecting modern software development techniques </strong> to supercharge the performance of the application, including:</p>
                            <ul className='px-3 pb-3'>
                                <li><i className="fa fa-circle-o  px-1" aria-hidden="true"></i>Microservices that perform specialized functions, such as payments, with high reliability.</li>
                                <li><i className="fa fa-circle-o  px-1" aria-hidden="true"></i>APIs to manage seamless data exchange with other applications.   </li>
                                <li><i className="fa fa-circle-o  px-1" aria-hidden="true"></i>Modern approaches to analytics to maximize the insight gained from data in the application. </li>
                            </ul>
                            <p className="mb-3"><strong className="gradient-text1">Integrating open source and commercial software products and components</strong> into the application to improve performance, time to delivery, and upgradability and lower the cost of development.</p>
                            <p className="mb-3"><strong className="gradient-text1">Embedding modern DevSecOps and CI/CD processes</strong>from the start to improve time to delivery, reliability, security, manageability, upgradability, and communication between all members of the software development team.</p>

                            <div className='d-flex p-5 justify-content-center'>
                                <img src="/images/optimoz/software-engineering.png" alt="Software Engineering" />
                            </div>

                            <p className="my-2">Our services include: </p>
                            <p className="mb-3"><strong className="gradient-text1">Technology Evaluation and Selection </strong>– In any software development effort, several key technology choices need to be made based on the desired outcome, cost of delivery, skills of the current and desired team, and user expectations. These choices include:</p>
                            <ul className='px-3 pb-3'>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Cloud platform selection </li>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Cloud infrastructure components </li>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Programming language </li>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Databases </li>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>AI/ML components </li>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Analytics and reporting tools </li>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Third-party components, such as microservices </li>
                            </ul>
                            <p className="mb-3">We do the research and make fact-based recommendations on the technologies and approaches that will be suit your needs.</p>

                            <p className="mb-3"><strong className="gradient-text1">Product Management </strong>– We work with your enterprise to make the architectural decisions that will drive the performance of an application, including.</p>
                            <ul className='px-3 pb-3'>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Selecting the structural components and interfaces to use in the application.  </li>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Determining the expected interfaces between those components.  </li>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Configuring selected components into appropriate subsystems.  </li>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Defining the interfaces between components.  </li>
                                <li><i className="fa fa-circle-o px-1" aria-hidden="true"></i>Aligning architectural decisions with business requirements.  </li>

                            </ul>
                            <p className="mb-3"><strong className="gradient-text1">Software Engineering </strong>–  We put in place and manage software development teams that can get the job done. Our teams typically include application architects, senior and mid-level developers, AI/ML experts, data analysts, QA engineers, DevSecOps professionals. We can either provide a full team or work as part of a blended team – providing specific technical skills and project leadership, as needed. </p>
                            <p className="mb-3"><strong className="gradient-text1">DevSecOps & CI/CD </strong>– We put in places practices to improve your software development processes, better integrate your technical teams, supercharge the performance of your applications and infrastructure, improve security, and ensure that software can be released on-time with very high quality. </p>
                            <p className="mb-3"><strong className="gradient-text1">Project Management  </strong>–  Many workstreams must come together to deliver a quality software application on time and on budget. We work with your team to project manage your software development project to completion.  </p>
                        </div>
                    </div>

                </div>
            </section>
            <FooterMain />
        </div>
    );
};
export default SoftwareEngineering;
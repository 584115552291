export const schema = {
    "title": "Ebook Form",
    "type": "object",
    "required": [
        "firstName",
        "lastName",
        "email"
    ],
    "properties": {
        "firstName": {
            "title": "First Name",
            "type": "string"
        },
        "lastName": {
            "title": "Last Name",
            "type": "string"
        },
        "email": {
            "type": "string",
            "title": "Email"
        }
    }
}
export const uischema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "Control",
            "scope": "#/properties/firstName",
            "label": "First Name",
            "options": {
                "placeholder": "Enter the first name",
                "focus": true
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/lastName",
            "label": "Last Name",
            "options": {
                "placeholder": "Enter the last name",
            }
        },
        {
            "type": "Control",
            "scope": "#/properties/email",
            "label": "Email",
            "options": {
                "placeholder": "Enter the email address",
            }
        },
    ]
}
export default function Portfolio() {
    return (
        <section id="portfolio" className="cube-portfolio1">
            <div className="container">
                {/* <div className="row align-items-center">
                    <div className="col-md-6 wow ">
                        <div className="title">
                            <h6 className="third-color mb-3">aixmoz / optalk</h6>
                            <h2>Making AI work for enterprises</h2>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4 wow ">
                        <ul>
                            <li>LLM agnostic: Use the LLM that is right for your AI applications.</li>
                            <li>Purpose-built components: Maximize usability, performance, and data integration and management.</li>
                            <li>Private, not public: Work securely with your data inside your enterprise - not on the public internet.</li>
                            <li>All data sources: Access and analyze any type of data from any drive, application, or database.</li>
                            <li>Flexible, powerful UI/UX: Interact productively with your AI applications.</li>
                            <li>Deploy anywhere: Run AI applications on any of the leading cloud services or on premises.</li>
                            <li>AI development expertise: Leverage our elite team to design, build, and operate your AI applications.</li>
                        </ul>
                    </div>
                </div> */}
                <div className="row wow ">
                    <div className="col-md-12">
                        {/* Portfolio Filters */}
                        <div id="js-filters-mosaic-flat" className="cbp-l-filters-button">
                            <div data-filter="*" className="cbp-filter-item-active cbp-filter-item">All</div>
                            <span className="text-blue">/</span>
                            <div data-filter=".product" className="cbp-filter-item">Product</div>
                            <span className="text-blue"> / </span>
                            <div data-filter=".service" className="cbp-filter-item">Service</div>
                        </div>

                        {/* Portfolio Items */}
                        <div id="js-grid-mosaic-flat" class="cbp cbp-l-grid-mosaic-flat">
                            <div className="cbp-item product service">
                                <a href="images/site/customer/mdcourts-documoz-300x600.png" className="cbp-caption cbp-lightbox">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/site/customer/mdcourts-documoz-300x600.png" alt="port-1" />
                                    </div>
                                    <div className="cbp-caption-activeWrap"></div>
                                    <div className="cbp-l-caption-alignCenter center-block">
                                        <div className="cbp-l-caption-body">
                                            <div className="plus"></div>
                                            <h5 className="text-white mb-1">Turnkey Automated Document Creation System</h5>
                                            <p className="text-white">Work @ MD Judiciary</p>
                                        </div>
                                    </div>

                                </a>
                            </div>
                            <div className="cbp-item service">
                                <a href="images/site/customer/inxeption-300x300.png" className="cbp-caption cbp-lightbox">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/site/customer/inxeption-300x300.png" alt="port-2" />
                                    </div>
                                    <div className="cbp-caption-activeWrap"></div>
                                    <div className="cbp-l-caption-alignCenter center-block">
                                        <div className="cbp-l-caption-body">
                                            <div className="plus"></div>
                                            <h5 className="text-white mb-1">DevOps and Cloud</h5>
                                            <p className="text-white">Work @ Inxeption</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item service">
                                <a href="images/site/customer/uscis-tics-600x600.png" className="cbp-caption cbp-lightbox">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/site/customer/uscis-tics-600x600.png" alt="port-4" />
                                    </div>
                                    <div className="cbp-caption-activeWrap"></div>
                                    <div className="cbp-l-caption-alignCenter center-block">
                                        <div className="cbp-l-caption-body">
                                            <div className="plus"></div>
                                            <h5 className="text-white mb-1">Digital Transformation</h5>
                                            <p className="text-white">Work @ USCIS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item service">
                                <a href="images/site/customer/ded-300x300.png" className="cbp-caption cbp-lightbox">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/site/customer/ded-300x300.png" alt="port-3" />
                                    </div>
                                    <div className="cbp-caption-activeWrap"></div>
                                    <div className="cbp-l-caption-alignCenter center-block">
                                        <div className="cbp-l-caption-body">
                                            <div className="plus"></div>
                                            <h5 className="text-white mb-1">College Financing Plan</h5>
                                            <p className="text-white">Work @ Department of Education</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item service">
                                <a href="images/site/customer/uscis-spedi-600x600.png" className="cbp-caption cbp-lightbox">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/site/customer/uscis-spedi-600x600.png" alt="port-5" />
                                    </div>
                                    <div className="cbp-caption-activeWrap"></div>
                                    <div className="cbp-l-caption-alignCenter center-block">
                                        <div className="cbp-l-caption-body">
                                            <div className="plus"></div>
                                            <h5 className="text-white mb-1">Platform Engineering and DevOps</h5>
                                            <p className="text-white">Integration Work @ USCIS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item product">
                                <a href="images/site/customer/openhit-600x300.png" className="cbp-caption cbp-lightbox">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/site/customer/openhit-600x300.png" alt="port-6" />
                                    </div>
                                    <div className="cbp-caption-activeWrap"></div>
                                    <div className="cbp-l-caption-alignCenter center-block">
                                        <div className="cbp-l-caption-body">
                                            <div className="plus"></div>
                                            <h5 className="text-white mb-1">OpenHIT</h5>
                                            <p className="text-white">Patient Portal</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item product service">
                                <a href="images/site/customer/usvi_doh_logo-300x300.png" className="cbp-caption cbp-lightbox">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/site/customer/usvi_doh_logo-300x300.png" alt="port-7" />
                                    </div>
                                    <div className="cbp-caption-activeWrap"></div>
                                    <div className="cbp-l-caption-alignCenter center-block">
                                        <div className="cbp-l-caption-body">
                                            <div className="plus"></div>
                                            <h5 className="text-white mb-1">Consumer Access Portal</h5>
                                            <p className="text-white">Work @ USVI Department of Health</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item service">
                                <a href="images/site/customer/cdc-logo-300x300.png" className="cbp-caption cbp-lightbox">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/site/customer/cdc-logo-300x300.png" alt="port-8" />
                                    </div>
                                    <div className="cbp-caption-activeWrap"></div>
                                    <div className="cbp-l-caption-alignCenter center-block">
                                        <div className="cbp-l-caption-body">
                                            <div className="plus"></div>
                                            <h5 className="text-white mb-1">Immunization Information System</h5>
                                            <p className="text-white">Work @ CDC</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        {/* To Enable Load More Button Remove The className="d-none" */}
                        <div id="js-loadMore-mosaic-flat" className="cbp-l-loadMore-bgbutton d-none">
                            <a href="loadmore.html" className="cbp-l-loadMore-link no-transition btn-rounded" rel="nofollow">
                                <span className="cbp-l-loadMore-defaultText no-transition ">Load More</span>
                                <span className="cbp-l-loadMore-loadingText ">Loading...</span>
                                <span className="cbp-l-loadMore-noMoreLoading">Finish</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

import React, { useState } from "react";
import { Worker } from '@react-pdf-viewer/core';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export function Capabilities() {
    const [fileUrl, setFileUrl] = useState('/files/Optimoz-Capabilities-Briefing-11262019.pdf');
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <section id="capabilities" className="wm-bg-section">
            <div className="container">
                {/* About */}
                <div className="row align-items-center wow ">
                    <div className="col-md-12">
                        {/* <div className="title">
                            <h3>Capabilities</h3>
                        </div> */}
                        Optimoz delivers leadership, experience, and expertise across multiple cutting edge technical disciplines. We’ve built effective Cloud Strategy and then successfully managed the development, deployment, and operation of cloud infrastructure using the latest Cloud platforms, technologies, tools, and processes. We have engineered great applications that served as systems of record – creating significant enterprise value. And we have built AI platforms and applications that have provided transformative insights for enterprises.

                    </div>

                    <div className="mt-1 col-md-12">
                        {/* <div className="title">
                            <h3>Capabilities</h3>
                        </div> */}
                        We also continuously upgrade our skills as new technologies come to market and constantly look for new and innovate technical approaches to overcome challenges and deliver solutions. Our core competencies include:
                    </div>
                </div>
            </div>
            <br />
            <center>
                <div className="col row-height-900">
                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
                        <Viewer
                            fileUrl={fileUrl}
                            plugins={[defaultLayoutPluginInstance]}
                            defaultScale={SpecialZoomLevel.PageFit}
                        />
                    </Worker>
                </div>

                <br /><br />
                <a href={fileUrl} target='_new'>
                    <h4>
                        Download Capabilities Briefing
                        &nbsp;
                        <i class="fa fa-download gradient-text1" aria-hidden="true"></i>
                    </h4>
                </a>
            </center>


            <div className='d-flex mt-5 justify-content-center'>
                <img src="/images/optimoz/stack.png" alt="Health IT Solutions" />
            </div>
        </section>
    );
}
import React, { useState, useCallback, useEffect } from 'react';
import { JsonForms } from '@jsonforms/react';
import {
    materialRenderers,
    materialCells

} from '@jsonforms/material-renderers';
import RichTextEditor from '../../ui/RichTextEditor';
import FileRenderer from '../../ui/JsonForms/FileRenderer';

import { schema, uischema } from './schemas';

// Constant
import { DEFAULT_FORM_VALIDATION_MODE, FORM_VALIDATE_MODE } from '../../../constants/configuration';

// API
import saveEntityData from '../../../apis/Entity/saveEntity';
import getEntityDataList from '../../../apis/Entity/getEntityList';

export function BlogPost() {
    const [data, setData] = useState({});
    const [errors, setErrors] = useState([]);
    const [validationMode, setValidationMode] = useState(DEFAULT_FORM_VALIDATION_MODE);

    const [toast, setToast] = useState(false);
    const [users, setUsers] = useState([]);
    const [updatedSchema, setUpdatedSchema] = useState(schema);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await getEntityDataList({
                    pageNumber: 1,
                    pageSize: 10,
                    apiUrl: "users",
                });
                let usersArray = [];
                if (Array.isArray(response.data)) {
                    response.data.map(user => {
                        usersArray.push({ value: user._id, label: user.firstName + " " + user.lastName });
                    });
                }
                setUsers(usersArray);
                setUpdatedSchema({
                    ...schema,
                    properties: {
                        ...schema.properties,
                        author: {
                            ...schema.properties.author,
                            enum: usersArray.map(user => user.label)
                        }
                    }
                })
            } catch (error) {
                console.error(error);
            }
        };
        fetchUsers();
    }, []);


    const richTextEditorRenderer = {
        tester: (uiSchema) => (uiSchema.options && uiSchema.options.richTextEditor ? 3 : -1),
        renderer:
            RichTextEditor
    };

    const fileRenderer = {
        tester: (uiSchema) => (uiSchema.options && uiSchema.options.fileRenderer ? 3 : -1), renderer: FileRenderer // Register the custom renderer
    }

    const renderers = [
        ...materialRenderers,
        richTextEditorRenderer,
        fileRenderer
    ];

    // Define an async function to submit the data
    const submitData = useCallback(async (event, payload) => {

        event.preventDefault();

        // set validation mode to validate on save
        setValidationMode(FORM_VALIDATE_MODE);

        // check if there are any errors
        if (errors && errors.length > 0) {
            return;
        }


        try {

            payload.author = users.find(user => user.label === payload.author).value // convert author name to author id
            await saveEntityData({ entityDataUrl: 'blogs', data: payload });
            setToast(true);
            setData({});
            setErrors([]);
            setValidationMode(DEFAULT_FORM_VALIDATION_MODE);
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error(error)
        }
    }, [setToast, errors]);


    return (
        <section className="career-section">
            <div className="container">
                <h3 className="gradient-text1 mb-4">Add new blog post</h3>
                <form onSubmit={(event) => submitData(event, data)}>
                    <JsonForms
                        schema={updatedSchema}
                        uischema={uischema}
                        renderers={renderers}
                        cells={materialCells}
                        validationMode={validationMode}
                        data={data}
                        onChange={({ errors, data }) => { setData(data); setErrors(errors); }}
                    />
                    <button type="submit"
                        className="btn btn-large btn-gradient btn-rounded mt-4">
                        Submit
                    </button>
                </form>
            </div>

            <div class="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: 1200 }}>
                <div id="liveToast" className={toast ? 'toast show' : 'toast'} role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header">
                        <strong class="me-auto">Blog Post</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setToast(false)}></button>
                    </div>
                    <div class="toast-body">
                        Blog saved successfully!
                    </div>
                </div>
            </div>

        </section>
    );
}